import React, { useState, useEffect, useCallback } from "react";
import {
  Center,
  Stack,
  HStack,
  AspectRatio,
  Heading,
  Text,
  Box,
  Pressable,
  Modal,
  Button,
  Avatar,
  VStack,
  Image,
} from "native-base";
import { GlobalStyles } from "../constants/styles";
import { MaterialIcons } from "@expo/vector-icons";
import { Image as NativeImage } from "react-native";
import ConfigureSettingsPopup from "./ConfigureSettingsPopup";
import ClipboardPopup from "./ClipboardPopup";
import { useSelector } from "react-redux";
import { GlobalConstants } from "../constants/constants";

const AdCardForCitizen = ({ data, setSelectedPost }) => {
  let user = useSelector((state) => state.user.user);
  console.log(data);
  const [imageUrl, setImageUrl] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [detailPopUp, setDetailPopUp] = useState(false);
  const [saveButtonText, setSaveButtonText] = useState(
    data?.user_saved_ads?.length > 0 ? "UnSave" : "Save"
  );

  const [isClipboardPopupOpen, setIsClipboardPopupOpen] = useState(false);

  const openClipboardPopup = () => {
    setIsClipboardPopupOpen(true);
  };

  const closeClipboardPopup = () => {
    setIsClipboardPopupOpen(false);
  };

  const shareClipboardPopup = async (selectedFriendIds) => {
    console.log(selectedFriendIds);
  };

  const openModal = (obj) => {
    setModalVisible(true);
    setImageUrl(data.ad_docs[0].url);
    // updateAdViews(obj);
  };

  const closeModal = () => {
    setImageUrl("");
    setModalVisible(false);
  };

  function getAvatarChar(fullName) {
    let nameShortCode = "";
    if (fullName && fullName.split(" ").length > 1) {
      nameShortCode =
        fullName.split(" ")[0].charAt(0).toUpperCase() +
        fullName.split(" ")[1].charAt(0).toUpperCase();
    } else {
      nameShortCode = fullName.charAt(0).toUpperCase();
    }
    return nameShortCode;
  }

  const handleChange = useCallback(async (value) => {
    setDetailPopUp(false);
  }, []);

  function getDisplayDate(year, month, day) {
    let today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    let compDate = new Date(year, month - 1, day); // month - 1 because January == 0
    let diff = today.getTime() - compDate.getTime(); // get the difference between today(at 00:00:00) and the date
    let stringDate = compDate.toDateString().split(" ");
    if (compDate.getTime() > today.getTime()) {
      return stringDate[1] + " " + stringDate[2];
    } else if (compDate.getTime() == today.getTime()) {
      return "Today";
    } else if (diff <= 24 * 60 * 60 * 1000) {
      return "Yesterday";
    } else {
      if (year != today.getFullYear()) {
        return stringDate[1] + " " + stringDate[2] + " " + stringDate[3];
      } else {
        return stringDate[1] + " " + stringDate[2];
      }
    }
  }

  useEffect(async () => {
    console.log(window?.innerWidth || yourDefaultWidth);
  }, []);

  return (
    <>
      <Modal
        size="xl"
        isOpen={modalVisible}
        onClose={closeModal}
        animationType="slide"
        transparent
      >
        <Modal.Content
          backgroundColor={"#43464b"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Modal.CloseButton />
          {imageUrl && (
            <Image
              w={[500, 700, 900]}
              h={[500, 700, 900]}
              source={{ uri: imageUrl }}
              resizeMode="contain"
              // style={{ width: "150%", height: "150%", resizeMode: "contain" }}
            />
          )}
        </Modal.Content>
      </Modal>
      <Center
        m={2}
        _text={{
          color: "warmGray.50",
          fontWeight: "medium",
        }}
      >
        <Box alignItems="center">
          <Box
            rounded="lg"
            borderColor={GlobalStyles.colors.mustard}
            borderWidth="1"
          >
            <Stack p="4" w={[250, 250, 600]}>
              <Stack space={2}>
                <Stack alignSelf="flex-end">
                  <Text fontSize="xs">
                    {data?.created_at
                      ? getDisplayDate(
                          data.created_at.split("-")[0],
                          data.created_at.split("-")[1],
                          data.created_at.split("-")[2]
                        )
                      : "few days ago"}
                  </Text>
                </Stack>
                <HStack ml="-2" mt={-5}>
                  <Avatar
                    size="sm"
                    source={{
                      uri: data.user?.logo_url ? data.user?.logo_url : "",
                    }}
                    bg={GlobalStyles.colors.blue}
                  >
                    {data.user?.advertiser_profile?.name
                      ? getAvatarChar(data.user.advertiser_profile.name)
                      : getAvatarChar("Anonymous")}
                  </Avatar>
                  <Heading
                    mt={2}
                    size="sm"
                    fontFamily={GlobalStyles.text.fontFamily}
                  >
                    {" "}
                    <Text fontSize={18}>
                      {data?.user?.advertiser_profile?.name
                        ? data.user.advertiser_profile.name
                        : "Anonymous"}
                    </Text>
                  </Heading>
                </HStack>
              </Stack>
              <Text
                fontWeight="400"
                mt={1}
                fontFamily={GlobalStyles.text.fontFamily}
                noOfLines={3}
              >
                {data.text}
                {/* Bengaluru (also called Bangalore) is the center of India's
                high-tech industry. The city is also known for its parks and
                nightlife. */}
              </Text>
            </Stack>
            <Box>
              <Pressable onPress={() => openModal(data)}>
                <AspectRatio ratio={3 / 2}>
                  <NativeImage
                    // style={{
                    //   width: "100%",
                    //   height: undefined,
                    //   aspectRatio: 2 / 1,
                    // }}
                    resizeMode={"cover"}
                    source={{
                      uri: data.ad_docs ? data.ad_docs[0]?.url : "",
                    }}
                    alt="image"
                  />
                </AspectRatio>
              </Pressable>
            </Box>
            {user.user_category == GlobalConstants.userCategories.citizen.id ? (
              <HStack p="1" w={[250, 250, 600]}>
                <Stack w={[window?.innerWidth > 600 && 200]}>
                  <Button
                    leftIcon={
                      <Image
                        source={require("../../assets/save.png")}
                        alt="Save"
                        size="7"
                      />
                      // <MaterialIcons name="save" size={24} color="black" />
                    }
                    isDisabled={data.savedAllowed ? false : true}
                    size="sm"
                    variant="unstyled"
                    onPress={() => {
                      data.isSave =
                        data?.user_saved_ads?.length > 0 ? false : true;
                      if (data.isSave) {
                        data.user_saved_ads = [{}];
                        setSaveButtonText("UnSave");
                      } else {
                        data.user_saved_ads = [];
                        setSaveButtonText("Save");
                      }
                      setSelectedPost(data);
                    }}
                  >
                    {saveButtonText}
                  </Button>
                </Stack>
                <Stack w={[window?.innerWidth > 600 && 200]}>
                  <Button
                    isDisabled={!data.text}
                    leftIcon={
                      <Image
                        source={require("../../assets/detail.png")}
                        alt="Save"
                        size="7"
                      />
                      // <MaterialIcons name="list-alt" size={24} color="black" />
                    }
                    size="sm"
                    variant="unstyled"
                    onPress={() => {
                      setDetailPopUp(true);
                    }}
                  >
                    Detail
                  </Button>
                </Stack>
                <Stack w={[window?.innerWidth > 600 && 200]}>
                  <Button
                    leftIcon={
                      <Image
                        source={require("../../assets/share.png")}
                        alt="Save"
                        size="7"
                      />
                    }
                    size="sm"
                    variant="unstyled"
                    onPress={openClipboardPopup}
                  >
                    Share
                  </Button>
                </Stack>
              </HStack>
            ) : (
              <HStack p="1" w={[250, 250, 600]}>
                <Stack w={[window?.innerWidth > 600 ? 600 : 220]}>
                  <Button
                    leftIcon={
                      <Image
                        source={require("../../assets/share.png")}
                        alt="Save"
                        size="7"
                      />
                    }
                    size="sm"
                    variant="unstyled"
                    onPress={openClipboardPopup}
                  >
                    Share
                  </Button>
                </Stack>
              </HStack>
            )}
          </Box>
        </Box>
      </Center>

      <ClipboardPopup
        isOpen={isClipboardPopupOpen}
        onClose={closeClipboardPopup}
        onShare={shareClipboardPopup}
        url={window.location.host + "/invite/ad/" + data.id}
        friends={[]}
      />

      {detailPopUp ? (
        <ConfigureSettingsPopup
          heading="Detail"
          body={data.text}
          data={detailPopUp}
          onChange={handleChange}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AdCardForCitizen;
