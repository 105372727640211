import React, { useState, useEffect } from "react";
import { AlertDialog, Button, Center, Text } from "native-base";
import { GlobalStyles } from "../constants/styles";

const ConfigureSettingsPopup = (props) => {
  const [isOpen, setIsOpen] = useState(props.data);

  const onClose = () => {
    setIsOpen(false);
    props.onChange(false);
  };

  const onOk = () => {
    setIsOpen(false);
    props.onChange(true);
  };


  const cancelRef = React.useRef(null);
  return (
    <Center>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>{props.heading}</AlertDialog.Header>
          <AlertDialog.Body>{props.body}</AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              {/* <Button
                variant="unstyled"
                colorScheme="coolGray"
                onPress={onClose}
                ref={cancelRef}
              >
                Cancel
              </Button> */}
              <Button bg={GlobalStyles.colors.blue} onPress={onOk}>
                Ok
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </Center>
  );
};

export default ConfigureSettingsPopup;
