import React, { useState, useEffect } from "react";
import { Center, Button, useToast, FormControl, Input } from "native-base";
import { GlobalStyles } from "../constants/styles";
import { GlobalConstants } from "../constants/constants";
import Toast from "./Toast";
import axios from "axios";

const ChangePassword = () => {
  const toast = useToast();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const changePassword = async () => {
    if (!oldPassword || !newPassword || !confirmPassword) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"please enter password!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    }
    if (oldPassword == newPassword) {
      setNewPassword("");
      setConfirmPassword("");
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"old and new password can't be same!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    }
    if (newPassword !== confirmPassword) {
      setConfirmPassword("");
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"confirm password doesn't match!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    }
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}users/reset/passsword`,
        {
          old_password: oldPassword,
          new_password: newPassword,
        }
      );
      if (resp.data.status) {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"Password Successfully Updated!!!"}
              />
            );
          },
          placement: "top",
        });
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={resp.data.status_msg}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Center>
        <FormControl>
          <FormControl.Label>Old Password</FormControl.Label>
          <Input
            value={oldPassword}
            type="password"
            onChange={(x) => {
              setOldPassword(x.target.value);
            }}
          />
        </FormControl>
        <FormControl>
          <FormControl.Label>New Password</FormControl.Label>
          <Input
            value={newPassword}
            type="password"
            onChange={(x) => {
              setNewPassword(x.target.value);
            }}
          />
        </FormControl>
        <FormControl>
          <FormControl.Label>Confirm Password</FormControl.Label>
          <Input
            value={confirmPassword}
            type="password"
            onChange={(x) => {
              setConfirmPassword(x.target.value);
            }}
          />
        </FormControl>
        <Button
          mt="2"
          size="sm"
          variant={"solid"}
          px="3"
          bg={GlobalStyles.colors.blue}
          borderColor={GlobalStyles.colors.mustard}
          borderWidth={2}
          onPress={changePassword}
        >
          Save
        </Button>
      </Center>
    </>
  );
};

export default ChangePassword;
