import React, { useState, useEffect, useRef } from "react";
import {
  VStack,
  HStack,
  Button,
  IconButton,
  Icon,
  Text,
  NativeBaseProvider,
  Center,
  Box,
  StatusBar,
  Divider,
  Stack,
  Menu,
  HamburgerIcon,
  Pressable,
  Avatar,
  Image,
  Modal,
} from "native-base";
import { StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { GlobalStyles } from "../constants/styles";
import { useSelector, useDispatch } from "react-redux";
import SetUser from "../redux/user/SetUser";
import { GlobalConstants } from "../constants/constants";

function AppBar() {
  const dispatch = useDispatch();
  let user = useSelector((state) => state.user.user);
  console.log("=================================");
  console.log(user);

  const navigation = useNavigation();

  const [signInColor, setSignInColor] = useState(GlobalStyles.colors.blue);
  const [signInTextColor, setSignInTextColor] = useState(
    GlobalStyles.colors.white
  );
  const [signUpColor, setSignUpColor] = useState(GlobalStyles.colors.blue);
  const [signUpTextColor, setSignUpTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [signInGuestColor, setSignInGuestColor] = useState(GlobalStyles.colors.blue);
  const [signInGuestTextColor, setSignInGuestTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [settingsColor, setSettingsColor] = useState(GlobalStyles.colors.blue);
  const [settingsTextColor, setSettingsTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [headerNameColor, setHeaderNameColor] = useState(
    GlobalStyles.colors.blue
  );

  const [whyTownTicksColor, setWhyTownTicksColor] = useState(
    GlobalStyles.colors.blue
  );

  const [modalVisible, setModalVisible] = useState(false);
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  useEffect(() => {
    changeColor("");
  }, []);

  function getAvatarChar(email) {
    let nameShortCode = "";
    if (email) {
      nameShortCode = email.charAt(0).toUpperCase();
    }
    return nameShortCode;
  }

  function changeColor(btnType) {
    if (btnType === "WhyTownTicks") {
      setWhyTownTicksColor(GlobalStyles.colors.mustard);
      setSignUpColor(GlobalStyles.colors.blue);
      setSignInColor(GlobalStyles.colors.blue);
      setSignInGuestColor(GlobalStyles.colors.blue);
      setSignUpTextColor(GlobalStyles.colors.white);
      setSignInTextColor(GlobalStyles.colors.white);
      setSignInGuestTextColor(GlobalStyles.colors.white);
    } else if (btnType === "SignUp") {
      setWhyTownTicksColor(GlobalStyles.colors.blue);
      setSignUpColor(GlobalStyles.colors.white);
      setSignInColor(GlobalStyles.colors.blue);
      setSignInGuestColor(GlobalStyles.colors.blue);
      setSignUpTextColor(GlobalStyles.colors.mustard);
      setSignInTextColor(GlobalStyles.colors.white);
      setSignInGuestTextColor(GlobalStyles.colors.white);
    } else if (btnType === "SignIn") {
      setWhyTownTicksColor(GlobalStyles.colors.blue);
      setSignUpColor(GlobalStyles.colors.blue);
      setSignInColor(GlobalStyles.colors.white);
      setSignInGuestColor(GlobalStyles.colors.blue);
      setSignUpTextColor(GlobalStyles.colors.white);
      setSignInTextColor(GlobalStyles.colors.mustard);
      setSignInGuestTextColor(GlobalStyles.colors.white);
    } else if (btnType === "SignInGuest") {
      setWhyTownTicksColor(GlobalStyles.colors.blue);
      setSignUpColor(GlobalStyles.colors.blue);
      setSignInColor(GlobalStyles.colors.blue);
      setSignInGuestColor(GlobalStyles.colors.white);
      setSignUpTextColor(GlobalStyles.colors.white);
      setSignInTextColor(GlobalStyles.colors.white);
      setSignInGuestTextColor(GlobalStyles.colors.mustard);
    } else if (btnType === "Settings") {
      setWhyTownTicksColor(GlobalStyles.colors.blue);
      setSignUpColor(GlobalStyles.colors.blue);
      setSignInColor(GlobalStyles.colors.blue);
      setSignInGuestColor(GlobalStyles.colors.blue);
      setSignUpTextColor(GlobalStyles.colors.white);
      setSignInTextColor(GlobalStyles.colors.white);
      setSignInGuestTextColor(GlobalStyles.colors.white);
    } else {
      setWhyTownTicksColor(GlobalStyles.colors.blue);
      setSignUpColor(GlobalStyles.colors.blue);
      setSignInColor(GlobalStyles.colors.blue);
      setSignInGuestColor(GlobalStyles.colors.blue);
      setSignUpTextColor(GlobalStyles.colors.white);
      setSignInTextColor(GlobalStyles.colors.white);
      setSignInGuestTextColor(GlobalStyles.colors.white);
    }
  }

  return (
    <>
      <StatusBar bg="#3700B3" barStyle="light-content" />
      <Center>
        <Box safeAreaTop bg="white" />
        <HStack
          bg="muted.100"
          px="1"
          py="3"
          justifyContent="space-between"
          alignItems="center"
          w="100%"
          maxW="900"
        >
          <Center w={[75, 150, 300]}>
            <Pressable
              onPress={() => {
                // window.location.reload();
                navigation.navigate("Home");
              }}
            >
              <HStack alignItems="center">
                <Image
                  source={require("../../assets/tt_logo.png")}
                  alt="Alternate Text"
                  size="xs"
                />
                {window?.innerWidth > 780 ? (
                  <>
                    <Text style={styles.textWithShadow}>Town Ticks</Text>
                  </>
                ) : (
                  <></>
                )}
              </HStack>
            </Pressable>
          </Center>
          <Center w={[75, 150, 300]}>
            <Stack
              flexDirection={window?.innerWidth < 415 ? "column" : "row"}
              justifyContent="center"
            >
              {!user ? (
                <Button
                  onPress={() => {
                    changeColor("WhyTownTicks");
                    navigation.navigate("WhyTownTicks");
                  }}
                  colorScheme="primary"
                  size="md"
                  variant="link"
                >
                  <Text
                    w={[75, 150, 150]}
                    color={whyTownTicksColor}
                    fontFamily={GlobalStyles.text.fontFamily}
                    fontSize={"lg"}
                    fontWeight="bold"
                  >
                    Why Town Ticks?
                  </Text>
                </Button>
              ) : (
                <>
                  {/* <Button
                    size="sm"
                    variant={"solid"}
                    px="3"
                    bg={settingsColor}
                    borderColor={settingsTextColor}
                    borderWidth={2}
                    onPress={() => {
                      changeColor("Settings");
                      navigation.navigate("CitizenMainPage");
                    }}
                  >
                    <Text fontWeight="bold" color={settingsTextColor}>
                      Settings
                    </Text>
                  </Button> */}
                </>
              )}
            </Stack>
          </Center>
          <Center w={[75, 150, 300]}>
            <Stack
              flexDirection={window?.innerWidth < 415 ? "column" : "row"}
              justifyContent="center"
              mx={10}
            >
              {!user ? (
                <>
                    <Button
                    size="sm"
                    variant={"solid"}
                    px="3"
                    bg={signInGuestColor}
                    borderColor={signInGuestTextColor}
                    borderWidth={2}
                    onPress={() => {
                      changeColor("SignInGuest");
                      navigation.navigate("SignInGuest");
                    }}
                  >
                    <Text fontWeight="bold" color={signInGuestTextColor}>
                      Guest
                    </Text>
                  </Button>
                  {/* <Button
                    size="sm"
                    variant={"solid"}
                    px="3"
                    bg={GlobalStyles.colors.blue}
                    borderColor={GlobalStyles.colors.white}
                    borderWidth={2}
                    onPress={() => {
                      setModalVisible(!modalVisible);
                    }}
                  >
                    <Text fontWeight="bold" color={GlobalStyles.colors.white}>
                      Contact Us
                    </Text>
                  </Button> */}
                  <Button
                    size="sm"
                    variant={"solid"}
                    px="3"
                    bg={signUpColor}
                    borderColor={signUpTextColor}
                    borderWidth={2}
                    onPress={() => {
                      changeColor("SignUp");
                      navigation.navigate("SignUp");
                    }}
                  >
                    <Text fontWeight="bold" color={signUpTextColor}>
                      Sign Up
                    </Text>
                  </Button>
                  <Button
                    size="sm"
                    variant={"solid"}
                    px="3"
                    bg={signInColor}
                    borderColor={signInTextColor}
                    borderWidth={2}
                    onPress={() => {
                      changeColor("SignIn");
                      navigation.navigate("SignIn");
                    }}
                  >
                    <Text fontWeight="bold" color={signInTextColor}>
                      Sign In
                    </Text>
                  </Button>
                </>
              ) : (
                <>
                  <Menu
                    w="190"
                    placement={"bottom right"}
                    trigger={(triggerProps) => {
                      return (
                        <Pressable
                          accessibilityLabel="More options menu"
                          {...triggerProps}
                        >
                          <HamburgerIcon size="md" mt={2} />
                          {/* <Avatar
                            size="sm"
                            source={{
                              uri: GlobalConstants.notFoundImageUrl,
                            }}
                            bg={GlobalStyles.colors.blue}
                          >
                            {getAvatarChar(user.email)}
                          </Avatar> */}
                        </Pressable>
                      );
                    }}
                  >
                    <Menu.Item isDisabled>
                      <Text
                        fontWeight="bold"
                        fontFamily={GlobalStyles.text.fontFamily}
                        color={GlobalStyles.colors.blue}
                      >
                        {user.profile ? user.profile.name : user.email}
                      </Text>
                    </Menu.Item>
                    <Menu.Item
                      onPress={() => {
                        changeColor("Settings");
                        navigation.navigate("Settings");
                      }}
                    >
                      <Text color={GlobalStyles.colors.blue}>Settings</Text>
                    </Menu.Item>
                    <Menu.Item
                      onPress={() => {
                        setModalVisible(!modalVisible);
                      }}
                    >
                      <Text color={GlobalStyles.colors.blue}>Contact Us</Text>
                    </Menu.Item>
                    <Menu.Item
                      onPress={() => {
                        dispatch(SetUser.action(null));
                        navigation.navigate("Home");
                      }}
                    >
                      <Text color={GlobalStyles.colors.blue}>Logout</Text>
                    </Menu.Item>
                  </Menu>
                </>
              )}
            </Stack>
          </Center>
        </HStack>
        <Divider bg={GlobalStyles.colors.mustard} w="100%" />
      </Center>

      <Modal
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
      >
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Contact Us</Modal.Header>
          <Modal.Body>
            <Text>
              To contact or any feedback about Town Ticks, write to
              <Text color={GlobalStyles.colors.blue}>
                {" "}
                support.townticks@novelma.com
              </Text>
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  setModalVisible(false);
                }}
              >
                Close
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  textWithShadow: {
    fontSize: 30,
    color: GlobalStyles.colors.mustard,
    fontFamily: GlobalStyles.text.fontFamily,
    textShadowColor: "#585858",
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 0,
  },
});

export default AppBar;
