import React, { useEffect } from "react";
import {
  Text,
  HStack,
  Switch,
  useColorMode,
  NativeBaseProvider,
  extendTheme,
  Box,
  StatusBar,
} from "native-base";
import { store, persistor } from "./src/redux";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { registerTranslation } from "react-native-paper-dates";
import AppNavigation from "./src/navigators/AppNavigation";
import { GlobalConstants } from "./src/constants/constants";
import { Analytics, PageHit } from "expo-analytics";
import { NavigationContainer, useNavigationContainerRef } from "@react-navigation/native";

// Define the config
const config = {
  useSystemColorMode: false,
  initialColorMode: "dark",
};

// extend the theme
export const theme = extendTheme({ config });
function loadGA() {
  // Create a script element
  const script = document.createElement("script");
  script.async = true;
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-Z3VY4GF3N9";
  document.head.appendChild(script);

  // Add the Google Analytics initialization script
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "G-Z3VY4GF3N9");
  };
}

export default function App() {
 
  
 
    useEffect(() => {
      loadGA(); // Load Google Analytics when the app loads
    }, []);
  
  const navigationRef = useNavigationContainerRef();
  const analytics = new Analytics(GlobalConstants.googleTrackingId);

  useEffect(() => {
    // Function to log the page hit
    const logPageHit = (screenName) => {
      analytics
        .hit(new PageHit(screenName))
        .then(() => {
          console.log(`Analytics hit recorded for: ${screenName}`)
        })
        .catch((e) => {
          console.log(`Analytics hit failed: ${e.message}`)
        });
    };

    // Listen to navigation state changes to track screen visits
    const unsubscribe = navigationRef.addListener("state", () => {
      const currentRoute = navigationRef.getCurrentRoute();
      console.log(currentRoute,"Route")
      if (currentRoute && currentRoute.name) {
        logPageHit(currentRoute.name); // Log the page hit for the current screen
      }
      else {
        console.log("Current route is undefined or doesn't have a name.");
      }
    });

    // Log the initial page hit for the Home screen
    logPageHit("App"); 

    // Cleanup the listener on unmount
    return () => {
      unsubscribe();
    };
  }, [navigationRef, analytics]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <NativeBaseProvider>
          <Box
            _dark={{ bg: "blueGray.800" }}
            _light={{ bg: "muted.100" }}
            flex={1}
            safeAreaTop
          >
            <StatusBar style="auto" />
            <AppNavigation />
          </Box>
        </NativeBaseProvider>
      </PersistGate>
    </Provider>
  );
}

// Color Switch Component
function ToggleDarkMode() {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <HStack space={2} alignItems="center">
      <Text>Dark</Text>
      <Switch
        isChecked={colorMode === "light"}
        onToggle={toggleColorMode}
        aria-label={
          colorMode === "light" ? "switch to dark mode" : "switch to light mode"
        }
      />
      <Text>Light</Text>
    </HStack>
  );
}

registerTranslation("en-GB", {
  save: "Save",
  selectSingle: "Select Date",
  selectMultiple: "Select Dates",
  selectRange: "Select Range",
  notAccordingToDateFormat: (inputFormat) =>
    `Date format must be ${inputFormat}`,
  mustBeHigherThan: (date) => `Must be later then ${date}`,
  mustBeLowerThan: (date) => `Must be earlier then ${date}`,
  mustBeBetween: (startDate, endDate) =>
    `Must be between ${startDate} - ${endDate}`,
  dateIsDisabled: "Day is not allowed",
  previous: "Previous",
  next: "Next",
  typeInDate: "Type in date",
  pickDateFromCalendar: "Pick date from calendar",
  close: "Close",
});
