import React from "react";
import {
  Divider,
  Flex,
  Box,
  Heading,
  Center,
  NativeBaseProvider,
  Text,
  ZStack,
  HStack,
  Stack,
  ScrollView,
  Button,
  VStack,
  Image,
} from "native-base";
import { ImageBackground, StyleSheet } from "react-native";
import { GlobalStyles } from "../constants/styles";
import { useNavigation } from "@react-navigation/native";

const WhyTownTicks = () => {
  const navigation = useNavigation();

  return (
    <>
      <ScrollView>
        <Center bg={GlobalStyles.colors.white}>
          <Box safeAreaTop bg="white" />
          <Stack
            justifyContent="space-between"
            alignItems="center"
            w={[900, 900, 900]}
            bg={GlobalStyles.colors.white}
            mt={5}
            flexDirection={window?.innerWidth < 415 ? "column" : "row"}
          >
            <Stack w={[400, 400, 400]}>
              <Text
                fontFamily={GlobalStyles.text.fontFamily}
                fontSize="4xl"
                fontWeight="bold"
                color={GlobalStyles.colors.mustard}
                m={20}
              >
                Responsible Social Media
              </Text>
            </Stack>

            <Stack w={[400, 400, 500]}>
              <VStack>
                <HStack
                  m={3}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box w={[100, 100, 150]}>
                    <Image
                      source={require("../../assets/WTT_T_1.png")}
                      alt="Alternate Text"
                      resizeMode="contain"
                      h={[100, 100, 100]}
                      w={[100, 100, 150]}
                    />
                  </Box>
                  <Box w={[250, 250, 450]}>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      fontWeight="bold"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      More Signal Less Noise.
                    </Text>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      With careful selection of content sources, only high
                      quality and relevant content comes on Town-Ticks.
                    </Text>
                  </Box>
                </HStack>

                <HStack
                  m={3}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box w={[100, 100, 150]}>
                    <Image
                      source={require("../../assets/WTT_T_2.png")}
                      alt="Alternate Text"
                      resizeMode="contain"
                      h={[100, 100, 100]}
                      w={[100, 100, 150]}
                    />
                  </Box>
                  <Box w={[250, 250, 450]}>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      fontWeight="bold"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      Respect Privacy.
                    </Text>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      With no targeted Ads, we do not need personal information
                      and no possibility it will be exploited or sold.
                    </Text>
                  </Box>
                </HStack>

                <HStack
                  m={3}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box w={[100, 100, 150]}>
                    <Image
                      source={require("../../assets/WTT_T_3.png")}
                      alt="Alternate Text"
                      resizeMode="contain"
                      h={[100, 100, 100]}
                      w={[100, 100, 150]}
                    />
                  </Box>
                  <Box w={[250, 250, 450]}>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      fontWeight="bold"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      Fighting Information Overload.
                    </Text>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      Other social media push content about everything and about
                      every issue. We stick to city where you live your life.
                    </Text>
                  </Box>
                </HStack>

                <HStack
                  m={3}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box w={[100, 100, 150]}>
                    <Image
                      source={require("../../assets/WTT_T_4.png")}
                      alt="Alternate Text"
                      resizeMode="contain"
                      h={[100, 100, 100]}
                      w={[100, 100, 150]}
                    />
                  </Box>
                  <Box w={[250, 250, 450]}>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      fontWeight="bold"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      We do not control what people see.
                    </Text>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      We have no recommendation algorithm and therefore
                      unequipped us from mind shaping or propaganda.
                    </Text>
                  </Box>
                </HStack>

                <HStack
                  m={3}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box w={[100, 100, 150]}>
                    <Image
                      source={require("../../assets/WTT_T_5.png")}
                      alt="Alternate Text"
                      resizeMode="contain"
                      h={[100, 100, 100]}
                      w={[100, 100, 150]}
                    />
                  </Box>
                  <Box w={[250, 250, 450]}>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      fontWeight="bold"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      No Hate Speech.
                    </Text>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      We do not enable users to leave comments thus killing
                      possibility of hate speech or bullying in the bud.
                    </Text>
                  </Box>
                </HStack>

                <HStack
                  m={3}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box w={[100, 100, 150]}>
                    <Image
                      source={require("../../assets/WTT_T_6.png")}
                      alt="Alternate Text"
                      resizeMode="contain"
                      h={[100, 100, 100]}
                      w={[100, 100, 150]}
                    />
                  </Box>
                  <Box w={[250, 250, 450]}>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      fontWeight="bold"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 350]}
                    >
                      No Addiction.
                    </Text>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 350]}
                    >
                      We do not have short  videos to increase short term
                      dopamine levels. Addiction and more time spent on platform
                      is not our goal.
                    </Text>
                  </Box>
                </HStack>
              </VStack>
            </Stack>
          </Stack>

          <HStack
            justifyContent="space-between"
            alignItems="center"
            w={[900, 900, 900]}
            bg={GlobalStyles.colors.white}
            mt={5}
          >
            <Divider bg={GlobalStyles.colors.mustard} w="100%" />
          </HStack>

          <Stack
            justifyContent="space-between"
            alignItems="center"
            w={[900, 900, 900]}
            bg={GlobalStyles.colors.white}
            mt={5}
            flexDirection={window?.innerWidth < 415 ? "column" : "row"}
          >
            <Stack w={[400, 400, 400]}>
              <Text
                fontFamily={GlobalStyles.text.fontFamily}
                fontSize="4xl"
                fontWeight="bold"
                color={GlobalStyles.colors.mustard}
                m={20}
              >
                Value For Citizens
              </Text>
            </Stack>

            <Stack w={[400, 400, 500]}>
              <VStack>
                <HStack
                  m={3}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box w={[100, 100, 150]}>
                    <Image
                      source={require("../../assets/WTT_T_7.png")}
                      alt="Alternate Text"
                      resizeMode="contain"
                      h={[100, 100, 100]}
                      w={[100, 100, 150]}
                    />
                  </Box>
                  <Box w={[250, 250, 450]}>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      fontWeight="bold"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      Active Urban Living.
                    </Text>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      We bring lot of content to enjoy urban life outside work,
                      every day.
                    </Text>
                  </Box>
                </HStack>

                <HStack
                  m={3}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box w={[100, 100, 150]}>
                    <Image
                      source={require("../../assets/WTT_T_8.png")}
                      alt="Alternate Text"
                      resizeMode="contain"
                      h={[100, 100, 100]}
                      w={[100, 100, 150]}
                    />
                  </Box>
                  <Box w={[250, 250, 450]}>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      fontWeight="bold"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      Customized Newsfeeds:
                    </Text>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      City Updates come through independent real time newsfeeds
                      which user can turn on and off anytime.
                    </Text>
                  </Box>
                </HStack>

                <HStack
                  m={3}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box w={[100, 100, 150]}>
                    <Image
                      source={require("../../assets/WTT_T_9.png")}
                      alt="Alternate Text"
                      resizeMode="contain"
                      h={[100, 100, 100]}
                      w={[100, 100, 150]}
                    />
                  </Box>
                  <Box w={[250, 250, 450]}>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      fontWeight="bold"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      Useful Content:
                    </Text>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      Keep track of events, deals, new openings, City Government
                      announcements and updates from fav business.
                    </Text>
                  </Box>
                </HStack>
              </VStack>
            </Stack>
          </Stack>

          <HStack
            justifyContent="space-between"
            alignItems="center"
            w={[900, 900, 900]}
            bg={GlobalStyles.colors.white}
            mt={5}
          >
            <Divider bg={GlobalStyles.colors.mustard} w="100%" />
          </HStack>

          <Stack
            justifyContent="space-between"
            alignItems="center"
            w={[900, 900, 900]}
            bg={GlobalStyles.colors.white}
            mt={5}
            flexDirection={window?.innerWidth < 415 ? "column" : "row"}
          >
            <Stack w={[400, 400, 400]}>
              <Text
                fontFamily={GlobalStyles.text.fontFamily}
                fontSize="4xl"
                fontWeight="bold"
                color={GlobalStyles.colors.mustard}
                m={20}
              >
                Value For Business
              </Text>
            </Stack>

            <Stack w={[400, 400, 500]}>
              <VStack>
                <HStack
                  m={3}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box w={[100, 100, 150]}>
                    <Image
                      source={require("../../assets/WTT_T_10.png")}
                      alt="Alternate Text"
                      resizeMode="contain"
                      h={[100, 100, 100]}
                      w={[100, 100, 150]}
                    />
                  </Box>
                  <Box w={[250, 250, 450]}>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      fontWeight="bold"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      Updates on Platform Meant For Updates:
                    </Text>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      Send your updates unobstructed to your followers, where
                      they are always delivered without ranking or
                      recommendation.
                    </Text>
                  </Box>
                </HStack>

                <HStack
                  m={3}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box w={[100, 100, 150]}>
                    <Image
                      source={require("../../assets/WTT_T_11.png")}
                      alt="Alternate Text"
                      resizeMode="contain"
                      h={[100, 100, 100]}
                      w={[100, 100, 150]}
                    />
                  </Box>
                  <Box w={[250, 250, 450]}>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      fontWeight="bold"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      Cheap Ads & Free Posts:
                    </Text>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      {`\u25CF ${"You can post daily Ads much cheaper than social media campaign but much more effective."}`}
                    </Text>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      {`\u25CF ${"Your posts/ticks will be for followers but yours Ads will be for everyone."}`}
                    </Text>
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      fontSize="lg"
                      color={GlobalStyles.colors.blue}
                      w={[250, 250, 300]}
                    >
                      {`\u25CF ${"You can post deals for a newsfeed dedicated to deal and promotions."}`}
                    </Text>
                  </Box>
                </HStack>
              </VStack>
            </Stack>
          </Stack>
        </Center>
      </ScrollView>
    </>
  );
};

export default WhyTownTicks;
