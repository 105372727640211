import React, { useState, useEffect } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Center,
  ScrollView,
  Button,
  useToast,
  Stack,
  Icon,
  Spinner,
} from "native-base";
import { GlobalStyles } from "../../constants/styles";
import TicksView from "../../components/TicksView";
import { GlobalConstants } from "../../constants/constants";
import { useNavigation } from "@react-navigation/native";
import Toast from "../../components/Toast";
import { useSelector } from "react-redux";
import axios from "axios";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import AdCardForCitizen from "../../components/AdCardForCitizen";

const data = {
  "2023-04-21": [
    {
      id: 3,
      name: "Usman Khan",
      created_at: "2023-04-04",
      tick: "Good Day!",
    },
    {
      id: 4,
      name: "Waqas Khan",
      created_at: "2023-04-04",
      tick: "It is normal to forget details on a certain day to day activities, but many times we encounter such situations in our lives that leave a mark on our mind. Some things are good, and others are bad. Some things you want to forget but can’t and the others you want to cherish for the rest of your life.",
    },
  ],
  "2023-04-20": [
    {
      id: 15,
      name: "Waqas Khan",
      created_at: "2023-04-03",
      tick: "It is normal to forget details on a certain day to day activities, but many times we encounter such situations in our lives that leave a mark on our mind. Some things are good, and others are bad. Some things you want to forget but can’t and the others you want to cherish for the rest of your life.",
    },
  ],
  "2023-04-08": [
    {
      id: 1,
      name: "Usman Khan",
      created_at: "2023-04-02",
      tick: "One of the important events in my life was when I was going with my parents to visit my aunt who lived in Canada. It was the first time I was going to board a plane. I was excited about the journey that I could not sleep the whole night.",
    },
    {
      id: 2,
      name: "Ali Haider Khan",
      created_at: "2023-04-02",
      tick: "Cheer up, there!",
    },
  ],
  "2023-04-03": [
    {
      id: 3,
      name: "Usman Khan",
      created_at: "2023-04-01",
      tick: "Good Day!",
    },
    {
      id: 4,
      name: "Waqas Khan",
      created_at: "2023-04-01",
      tick: "It is normal to forget details on a certain day to day activities, but many times we encounter such situations in our lives that leave a mark on our mind. Some things are good, and others are bad. Some things you want to forget but can’t and the others you want to cherish for the rest of your life.",
    },
  ],
  "2023-03-25": [
    {
      id: 5,
      name: "Amber",
      created_at: "2023-03-25",
      tick: "I will call today.",
    },
  ],
  "2023-02-05": [
    {
      id: 6,
      name: "Usman Khan",
      created_at: "2023-02-05",
      tick: "One of the important events in my life was when I was going with my parents to visit my aunt who lived in Canada. It was the first time I was going to board a plane. I was excited about the journey that I could not sleep the whole night.",
    },
  ],
  "2022-12-25": [
    {
      id: 6,
      name: "Hamza Khan",
      created_at: "2022-12-25",
      tick: "One of the important events in my life was when I was going with my parents to visit my aunt who lived in Canada. It was the first time I was going to board a plane. I was excited about the journey that I could not sleep the whole night.",
    },
    {
      id: 6,
      name: "Hamza Khan",
      created_at: "2022-12-25",
      tick: "One of the important events in my life was when I was going with my parents to visit my aunt who lived in Canada. It was the first time I was going to board a plane. I was excited about the journey that I could not sleep the whole night.",
    },
  ],
};

const AdsMainPage = () => {
  let user = useSelector((state) => state.user.user);
  axios.defaults.headers.common = {
    Authorization: `bearer ${user ? user.token : null}`,
  };

  const toast = useToast();
  const navigation = useNavigation();

  const [productBtnTextColor, setProductBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [brandsBtnTextColor, setBrandsBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [informativeBtnTextColor, setInformativeBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [publicServiceBtnTextColor, setPublicServiceBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [politicalBtnTextColor, setPoliticalBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [othersBtnTextColor, setOtherBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [savedBtnTextColor, setSavedBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [selectedPost, setSelectedPost] = useState({});

  const [ads, setAds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [page, setPage] = useState();
  const [adCategoryType, setAdCategoryType] = useState(
    GlobalConstants.AdCategories.publicService.id
  );

  function changeColor(btnType) {
    if (btnType === "Product") {
      setProductBtnTextColor(GlobalStyles.colors.mustard);
      setBrandsBtnTextColor(GlobalStyles.colors.blue);
      setInformativeBtnTextColor(GlobalStyles.colors.blue);
      setPublicServiceBtnTextColor(GlobalStyles.colors.blue);
      setPoliticalBtnTextColor(GlobalStyles.colors.blue);
      setOtherBtnTextColor(GlobalStyles.colors.blue);
      setSavedBtnTextColor(GlobalStyles.colors.blue);
    } else if (btnType === "Brands") {
      setProductBtnTextColor(GlobalStyles.colors.blue);
      setBrandsBtnTextColor(GlobalStyles.colors.mustard);
      setInformativeBtnTextColor(GlobalStyles.colors.blue);
      setPublicServiceBtnTextColor(GlobalStyles.colors.blue);
      setPoliticalBtnTextColor(GlobalStyles.colors.blue);
      setOtherBtnTextColor(GlobalStyles.colors.blue);
      setSavedBtnTextColor(GlobalStyles.colors.blue);
    } else if (btnType === "Informative") {
      setProductBtnTextColor(GlobalStyles.colors.blue);
      setBrandsBtnTextColor(GlobalStyles.colors.blue);
      setInformativeBtnTextColor(GlobalStyles.colors.mustard);
      setPublicServiceBtnTextColor(GlobalStyles.colors.blue);
      setPoliticalBtnTextColor(GlobalStyles.colors.blue);
      setOtherBtnTextColor(GlobalStyles.colors.blue);
      setSavedBtnTextColor(GlobalStyles.colors.blue);
    } else if (btnType === "PublicService") {
      setProductBtnTextColor(GlobalStyles.colors.blue);
      setBrandsBtnTextColor(GlobalStyles.colors.blue);
      setInformativeBtnTextColor(GlobalStyles.colors.blue);
      setPublicServiceBtnTextColor(GlobalStyles.colors.mustard);
      setPoliticalBtnTextColor(GlobalStyles.colors.blue);
      setOtherBtnTextColor(GlobalStyles.colors.blue);
      setSavedBtnTextColor(GlobalStyles.colors.blue);
    } else if (btnType === "Political") {
      setProductBtnTextColor(GlobalStyles.colors.blue);
      setBrandsBtnTextColor(GlobalStyles.colors.blue);
      setInformativeBtnTextColor(GlobalStyles.colors.blue);
      setPublicServiceBtnTextColor(GlobalStyles.colors.blue);
      setPoliticalBtnTextColor(GlobalStyles.colors.mustard);
      setOtherBtnTextColor(GlobalStyles.colors.blue);
      setSavedBtnTextColor(GlobalStyles.colors.blue);
    } else if (btnType === "Others") {
      setProductBtnTextColor(GlobalStyles.colors.blue);
      setBrandsBtnTextColor(GlobalStyles.colors.blue);
      setInformativeBtnTextColor(GlobalStyles.colors.blue);
      setPublicServiceBtnTextColor(GlobalStyles.colors.blue);
      setPoliticalBtnTextColor(GlobalStyles.colors.blue);
      setSavedBtnTextColor(GlobalStyles.colors.blue);
      setOtherBtnTextColor(GlobalStyles.colors.mustard);
    } else if (btnType === "Saved") {
      setSavedBtnTextColor(GlobalStyles.colors.mustard);
    } else {
      setProductBtnTextColor(GlobalStyles.colors.blue);
      setBrandsBtnTextColor(GlobalStyles.colors.blue);
      setInformativeBtnTextColor(GlobalStyles.colors.blue);
      setPublicServiceBtnTextColor(GlobalStyles.colors.mustard);
      setPoliticalBtnTextColor(GlobalStyles.colors.blue);
      setSavedBtnTextColor(GlobalStyles.colors.blue);
      setOtherBtnTextColor(GlobalStyles.colors.blue);
    }
  }

  const adsFirstPage = async (adCategoryType) => {
    setIsDataLoading(true);
    setIsLoading(true);
    setAdCategoryType(adCategoryType);
    let data = await getAdsByCategoryId(adCategoryType, 1);
    setAds(data);
    setPage(2);
    setIsLoading(false);
    setIsDataLoading(false);
  };

  const adsNextPage = async () => {
    setIsLoading(true);
    let data = await getAdsByCategoryId(adCategoryType, page);
    //After the response increasing the offset for the next API call.
    console.log(data);

    setAds([...ads, ...data]);

    console.log(ads);

    setPage(page + 1);
    setIsLoading(false);
  };

  const getAdsByCategoryId = async (type, page, offSet = 3) => {
    setAdCategoryType(type);
    let data = [];
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}ad/category/` +
          type +
          `?page=` +
          page +
          `&limit=` +
          offSet
      );
      if (resp.data.status) {
        data = resp.data.data;
        if (data.length == offSet) {
          setShowLoadMore(true);
        } else {
          setShowLoadMore(false);
        }
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const onClick = async (data) => {
    console.log(data);
    setSelectedPost(data);
    if (data.isSave) {
      await saveAd(data.id);
    } else {
      await unSaveAd(data.id);
    }
    if (savedBtnTextColor == GlobalStyles.colors.mustard) {
      await getSavedAds(false);
    }
  };

  const unSaveAd = async (adId) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}ad/unsave`,
        {
          ad_id: adId,
        }
      );
      if (resp.data.status) {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"Ad unsaved scuccessfully!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to unsave ad!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveAd = async (adId) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}ad/save`,
        {
          ad_id: adId,
        }
      );
      if (resp.data.status) {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"Ad saved scuccessfully!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to save ad!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSavedAds = async (isLoading = true) => {
    setIsLoading(isLoading);
    setAds([]);
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}ad/saved/category/` + adCategoryType
      );
      if (resp.data.status) {
        setAds(resp.data.data);
        setIsLoading(false);
      } else {
        // toast.show({
        //   render: () => {
        //     return (
        //       <Toast
        //         color={GlobalStyles.colors.red}
        //         message={"No new feeds!!!"}
        //       />
        //     );
        //   },
        //   placement: "top",
        // });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    console.log(window?.innerWidth || yourDefaultWidth);
    changeColor("PublicService");
    await adsFirstPage(GlobalConstants.AdCategories.publicService.id);
  }, []);

  return (
    <>
      <ScrollView>
        <Center>
          <HStack bg="#F5F5F5" alignItems="center" w="100%" maxW="900">
            <Button
              ml={5}
              alignSelf={"start"}
              size="sm"
              variant="link"
              onPress={() => {
                navigation.navigate("Home");
              }}
            >
              Go to Home Page
            </Button>
          </HStack>
        </Center>
        <Center>
          <Box safeAreaTop bg="white" />
          <VStack
            bg="#F5F5F5"
            px="1"
            alignItems="center"
            justifyContent="center"
            w="100%"
            maxW="900"
          >
            <Stack
              alignItems="center"
              flexDirection={window?.innerWidth < 415 ? "column" : "row"}
            >
              <Stack
                w={[275, 275, 275]}
                justifyContent="center"
                flexDirection={"row"}
              >
                <Button
                  size={"sm"}
                  variant={"solid"}
                  m="1"
                  bg={GlobalStyles.colors.white}
                  borderWidth={1}
                  shadow={3}
                  borderColor={GlobalStyles.colors.grey}
                  onPress={() => {
                    changeColor("Product");
                    adsFirstPage(GlobalConstants.AdCategories.product.id);
                  }}
                >
                  <Text
                    fontSize={"md"}
                    fontWeight="bold"
                    fontFamily={GlobalStyles.text.fontFamily}
                    color={productBtnTextColor}
                  >
                    Product
                  </Text>
                </Button>
                <Button
                  size={"sm"}
                  variant={"solid"}
                  m="1"
                  bg={GlobalStyles.colors.white}
                  borderWidth={1}
                  shadow={3}
                  borderColor={GlobalStyles.colors.grey}
                  onPress={() => {
                    changeColor("Brands");
                    adsFirstPage(GlobalConstants.AdCategories.brands.id);
                  }}
                >
                  <Text
                    fontSize={"md"}
                    fontWeight="bold"
                    fontFamily={GlobalStyles.text.fontFamily}
                    color={brandsBtnTextColor}
                  >
                    Brands
                  </Text>
                </Button>
                <Button
                  size={"sm"}
                  variant={"solid"}
                  m="1"
                  bg={GlobalStyles.colors.white}
                  borderWidth={1}
                  shadow={3}
                  borderColor={GlobalStyles.colors.grey}
                  onPress={() => {
                    changeColor("Informative");
                    adsFirstPage(GlobalConstants.AdCategories.informative.id);
                  }}
                >
                  <Text
                    fontSize={"md"}
                    fontWeight="bold"
                    fontFamily={GlobalStyles.text.fontFamily}
                    color={informativeBtnTextColor}
                  >
                    Informative
                  </Text>
                </Button>
              </Stack>
              <Stack
                w={[270, 270, 270]}
                justifyContent="center"
                flexDirection={"row"}
              >
                <Button
                  size={"sm"}
                  variant={"solid"}
                  m="1"
                  bg={GlobalStyles.colors.white}
                  borderWidth={1}
                  shadow={3}
                  borderColor={GlobalStyles.colors.grey}
                  onPress={() => {
                    changeColor("PublicService");
                    adsFirstPage(GlobalConstants.AdCategories.publicService.id);
                  }}
                >
                  <Text
                    fontSize={"md"}
                    fontWeight="bold"
                    fontFamily={GlobalStyles.text.fontFamily}
                    color={publicServiceBtnTextColor}
                  >
                    Public Service
                  </Text>
                </Button>
                <Button
                  size={"sm"}
                  variant={"solid"}
                  m="1"
                  bg={GlobalStyles.colors.white}
                  borderWidth={1}
                  shadow={3}
                  borderColor={GlobalStyles.colors.grey}
                  onPress={() => {
                    changeColor("Political");
                    adsFirstPage(GlobalConstants.AdCategories.political.id);
                  }}
                >
                  <Text
                    fontSize={"md"}
                    fontWeight="bold"
                    fontFamily={GlobalStyles.text.fontFamily}
                    color={politicalBtnTextColor}
                  >
                    Political
                  </Text>
                </Button>

                <Button
                  size={"sm"}
                  variant={"solid"}
                  m="1"
                  bg={GlobalStyles.colors.white}
                  borderWidth={1}
                  shadow={3}
                  borderColor={GlobalStyles.colors.grey}
                  onPress={() => {
                    changeColor("Others");
                    adsFirstPage(GlobalConstants.AdCategories.others.id);
                  }}
                >
                  <Text
                    fontSize={"md"}
                    fontWeight="bold"
                    fontFamily={GlobalStyles.text.fontFamily}
                    color={othersBtnTextColor}
                  >
                    Others
                  </Text>
                </Button>
              </Stack>
            </Stack>
          </VStack>

          {user.user_category == GlobalConstants.userCategories.citizen.id && (
            <VStack
              bg="#F5F5F5"
              px="1"
              py="3"
              justifyContent="space-between"
              w="100%"
              maxW="900"
            >
              <Stack flexDirection={"row"}>
                <Button
                  size={"sm"}
                  variant={"solid"}
                  ml={10}
                  bg={GlobalStyles.colors.white}
                  borderWidth={1}
                  shadow={3}
                  borderColor={GlobalStyles.colors.grey}
                  onPress={() => {
                    changeColor("Saved");
                    getSavedAds();
                  }}
                >
                  <Text
                    fontSize={"md"}
                    fontWeight="bold"
                    fontFamily={GlobalStyles.text.fontFamily}
                    color={savedBtnTextColor}
                  >
                    Saved
                  </Text>
                </Button>
              </Stack>
            </VStack>
          )}

          <VStack
            bg="#F5F5F5"
            px="1"
            mx="5"
            alignItems="center"
            justifyContent="center"
            w="100%"
            maxW="900"
          >
            {isLoading ? (
              <>
                <Spinner
                  color={GlobalStyles.colors.mustard}
                  m={10}
                  size="lg"
                  accessibilityLabel="Loading posts"
                />
              </>
            ) : ads ? (
              <>
                <ScrollView
                  w={[260, 360, 610]}
                  showsVerticalScrollIndicator={false}
                  mt={-5}
                >
                  <VStack alignItems="center">
                    <Stack
                      justifyContent="center"
                      flexDirection={"row"}
                      space={3}
                      flexWrap="wrap"
                      m={5}
                    >
                      {ads.map((x, index) => {
                        x.savedAllowed = user ? true : false;

                        return (
                          <AdCardForCitizen
                            data={x}
                            setSelectedPost={onClick}
                          />
                        );
                      })}
                    </Stack>
                    {showLoadMore ? (
                      <>
                        <Button
                          isLoading={isLoading}
                          size="sm"
                          variant="outline"
                          onPress={adsNextPage}
                        >
                          Load More
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </VStack>
                </ScrollView>
              </>
            ) : (
              <></>
            )}
          </VStack>
        </Center>
      </ScrollView>
    </>
  );
};

export default AdsMainPage;
