import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GlobalConstants } from "../../constants/constants";
import axios from "axios";
import { Box, Stack, Text, useToast, ScrollView, Center } from "native-base";
import FeedCardForCitizen from "../../components/FeedCardForCitizen";
import { useSelector } from "react-redux";
import Toast from "../../components/Toast";
import { GlobalStyles } from "../../constants/styles";

const FeedInviteLandingPage = () => {
  const toast = useToast();

  let user = useSelector((state) => state.user.user);
  axios.defaults.headers.common = {
    Authorization: `bearer ${user ? user.token : null}`,
  };
  console.log(user);

  const { id } = useParams();

  const [selectedPost, setSelectedPost] = useState(null);

  const getAdById = async () => {
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}v1/posts/` + id
      );
      if (resp.data.status) {
        console.log(resp.data.data);
        var data = resp.data.data;
        data.savedAllowed = user ? true : false;
        setSelectedPost(data);
      } else {
        setSelectedPost(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClick = async (data) => {
    if (data.is_saved) {
      await unSavePost(data.id);
    } else {
      await savePost(data.id);
    }
  };

  const savePost = async (postId) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}posts/save`,
        {
          post_id: postId,
        }
      );
      if (resp.data.status) {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"Post saved scuccessfully!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to save post!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const unSavePost = async (postId) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}posts/unsave`,
        {
          post_id: postId,
        }
      );
      if (resp.data.status) {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"Post unsaved scuccessfully!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to unsave post!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    console.log(window?.innerWidth || yourDefaultWidth);
    await getAdById();
  }, []);

  return (
    <ScrollView>
      <Center bg={GlobalStyles.colors.lightGrey}>
        <Stack
          justifyContent="center"
          flexDirection={"row"}
          space={3}
          flexWrap="wrap"
          m={2}
        >
          {selectedPost ? (
            <FeedCardForCitizen
              data={selectedPost}
              setSelectedPost={onClick}
              friends={[]}
            />
          ) : (
            <Text mt={5}>No Record Found</Text>
          )}
        </Stack>
      </Center>
    </ScrollView>
  );
};

export default FeedInviteLandingPage;
