import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Modal,
  Input,
  Button,
  HStack,
  VStack,
  AlertDialog,
  Center,
  useToast,
  Stack,
  ScrollView,
  Box,
  Heading,
  FlatList,
  Spacer,
  FormControl,
  Checkbox,
} from "native-base";
import * as Clipboard from "expo-clipboard";
import { GlobalStyles } from "../constants/styles";
import Toast from "../components/Toast";
import { MaterialIcons, Ionicons } from "@expo/vector-icons";
import { truncateString } from "../utils/general";

const ClipboardPopup = ({ isOpen, onClose, onShare, url, friends }) => {
  const toast = useToast();

  const [selectedFriends, setSelectedFriends] = useState([]);

  const [copyText, setCopyText] = useState("Copy");
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isSharing, setIsSharing] = useState(false);

  const copyToClipboard = async () => {
    await Clipboard.setStringAsync(url);
    setCopyText("Copied !!!");
    // toast.show({
    //   render: () => {
    //     return (
    //       <Toast
    //         color={GlobalStyles.colors.green}
    //         message={"Successfully copied invite url!!!"}
    //       />
    //     );
    //   },
    //   placement: "top",
    // });
  };

  const onClosePopup = async () => {
    setSelectedFriends([]);
    setCopyText("Copy");
    onClose();
  };

  const onSelectAll = async (data) => {
    // console.log(data);

    // console.log(data.includes(-1));
    // console.log(data.includes(-1) && data.length < friends.length + 1);
    // if (data.includes(-1) && data.length < friends.length + 1) {
    //   setSelectedFriends([-1].concat(friends.map((obj) => obj.id)));
    // } else if (data.includes(-1)) {
    //   setSelectedFriends(data);
    // } else if (data.length == friends.length) {
    //   setSelectedFriends([]);
    // } else if (!data.includes(-1)) {
    //   setSelectedFriends(data);
    // } else {
    //   setSelectedFriends(data);
    // }

    // console.log(selectedFriends);
  };

  const onShareWithFriends = async () => {
    if (selectedFriends.length > 0) {
      setIsSharing(true); // Start loader
      try {
        await onShare(selectedFriends);
        setSelectedFriends([]);
        setCopyText("Copy");
      } catch (error) {
        console.error("Error sharing with friends:", error);
        toast.show({
          render: () => (
            <Toast
              color={GlobalStyles.colors.red}
              message="Failed to share with friends."
            />
          ),
          placement: "top",
          duration: 3000,
        });
      } finally {
        setIsSharing(false);
        setIsAllSelected(false) // Stop loader
      }
    } else {
      toast.show({
        render: () => (
          <Toast
            color={GlobalStyles.colors.red}
            message="Kindly select friend to share!!!"
          />
        ),
        placement: "top",
      });
    }
  };
  // Handle select/deselect of checkboxes
  const handleCheckboxChange = (selectedValues) => {
    // If "All" is selected, toggle all friends
    if (selectedValues.includes(-1)) {
      // Select all if none selected, or deselect all if already fully selected
      const newSelectedFriends = isAllSelected 
        ? [] 
        : friends.map(friend => friend.id);
      setSelectedFriends(newSelectedFriends);
      setIsAllSelected(!isAllSelected);
    } else {
      setSelectedFriends(selectedValues);
    }
  };

  return (
    <Box>
      <AlertDialog isOpen={isOpen} onClose={onClosePopup}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Share</AlertDialog.Header>
          <AlertDialog.Body>
            <Center h={75} w={[200, 300, 350]} rounded="md" shadow={3}>
              <FormControl w={[150, 250, 300]}>
                <Heading fontSize="sm" p="1">
                  Copy Link
                </Heading>
                <Input
                  type="text"
                  value={url}
                  InputRightElement={
                    <Button
                      size="xs"
                      rounded="none"
                      h="full"
                      variant={"outline"}
                      bg={GlobalStyles.colors.mustard}
                      onPress={() => {
                        copyToClipboard();
                      }}
                    >
                      <Text
                        fontFamily={GlobalStyles.text.fontFamily}
                        color={
                          copyText.toLowerCase() == "copy"
                            ? GlobalStyles.colors.blue
                            : GlobalStyles.colors.green
                        }
                        fontWeight="bold"
                      >
                        {copyText}
                      </Text>
                    </Button>
                  }
                  placeholder=""
                />
              </FormControl>
            </Center>
            {friends.length > 0 && (
              <Box
                mt={2}
                h={200}
                w={[200, 300, 350]}
                rounded="md"
                shadow={3}
              >
                <ScrollView >
                  <Box >
                    <Heading fontSize="sm" p="2">
                      Share with friends
                    </Heading>

                    <Checkbox.Group
                      onChange={handleCheckboxChange}
                      value={isAllSelected ? [-1, ...selectedFriends] : selectedFriends}
                      accessibilityLabel="choose friends"
                      
                    >
                      <Checkbox 
                        value={-1} 
                        isChecked={isAllSelected}
                        ml={4}
                        onChange={() => {
                          const allIds = friends.map(friend => friend.id);
                          setSelectedFriends(isAllSelected ? [] : allIds);
                          setIsAllSelected(!isAllSelected);
                        }}
                      >
                        <Text fontWeight={"bold"}> All</Text>
                      </Checkbox>

                      <FlatList width="100%"
                        data={friends}
                        renderItem={({ item }) => (
                          <Box
                            borderBottomWidth="1"
                            _dark={{
                              borderColor: "muted.50",
                            }}
                            borderColor="muted.300"
                            pl={["0", "4"]}
                            pr={["0", "5"]}
                            
                            
                          >
                            <HStack
                              space={[2, 3]}
                              justifyContent="space-between"
                              
                            >
                              <VStack>
                                <Checkbox value={item.id} size="sm">
                                  {window?.innerWidth < 415 &&
                                  item.friend.citizen_profile.name &&
                                  item.friend.citizen_profile.name
                                    ? truncateString(item.friend.citizen_profile.name, 10)
                                    : truncateString(item.friend.citizen_profile.name)}
                                </Checkbox>
                              </VStack>
                            </HStack>
                          </Box>
                        )}
                        keyExtractor={(item) => item.id}
                      />
                    </Checkbox.Group>
                  </Box>
                </ScrollView>
              </Box>
            )}
          </AlertDialog.Body>
          <AlertDialog.Footer justifyContent="center">
            <HStack>
              {/* <HStack m={2} alignSelf="flex-start">
                <Ionicons name="logo-facebook" size={24} color="blue" />
                <Ionicons name="logo-whatsapp" size={24} color="green" />
                <Ionicons name="logo-google" size={24} color="green" />
              </HStack> */}
              {friends.length > 0 && (
                <Button.Group space={2} >
                  <Button
                    size="xs"
                    h="full"
                    variant={"outline"}
                    bg={GlobalStyles.colors.mustard}
                    isLoading={isSharing} // Show loader when isLoading is true
                    isLoadingText="Sharing..."
                    onPress={() => {
                      onShareWithFriends();
                    }}
                  >
                    <Text
                      fontFamily={GlobalStyles.text.fontFamily}
                      color={GlobalStyles.colors.blue}
                      fontWeight="bold"
                    >
                      Share
                    </Text>
                  </Button>
                </Button.Group>
              )}
            </HStack>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </Box>

    // <Modal isOpen={isOpen} onClose={onClose}>
    //   <Modal.Content maxWidth="400px">
    //     <Modal.Header>Share</Modal.Header>
    //     <Modal.Body>
    //       <VStack space={3} alignItems="center">
    //         <Input
    //           placeholder="Enter text"
    //           value={inputValue}
    //           onChangeText={(text) => setInputValue(text)}
    //         />
    //         <HStack space={2}>
    //           <Button onPress={copyToClipboard}>Copy to Clipboard</Button>
    //           <Button colorScheme="secondary" onPress={onClose}>
    //             Close
    //           </Button>
    //         </HStack>
    //       </VStack>
    //     </Modal.Body>
    //   </Modal.Content>
    // </Modal>
  );
};

export default ClipboardPopup;
