import React, { useEffect, useState } from "react";
import { Center, Text, Button } from "native-base";
import { useSelector } from "react-redux";
import axios from "axios";
import { GlobalConstants } from "../../constants/constants";
import { GlobalStyles } from "../../constants/styles";
// import { useNavigation } from "@react-navigation/native";

const StripePaymentCompletePage = () => {
  // const navigation = useNavigation();
  let user = useSelector((state) => state.user.user);
  axios.defaults.headers.common = {
    Authorization: `bearer ${user ? user.token : null}`,
  };

  const [message, setMessage] = useState(null);

  useEffect(() => {
    // Get the query parameters from the URL
    const urlParams = new URLSearchParams(window.location.search);

    // Read individual parameters
    const paymentIntent = urlParams.get("payment_intent");
    const paymentIntentClientSecret = urlParams.get(
      "payment_intent_client_secret"
    );
    const redirectStatus = urlParams.get("redirect_status");

    // Do something with the parameters
    console.log("Payment Intent:", paymentIntent);
    console.log("Payment Intent Client Secret:", paymentIntentClientSecret);
    console.log("Redirect Status:", redirectStatus);

    ProcessTransaction({
      // ad_Id: paymentIntent,
      // intent: paymentIntent,
      secret: paymentIntentClientSecret,
      status: redirectStatus,
    });
  }, []);

  const ProcessTransaction = async (payload) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}payment/process`,
        payload
      );
      if (resp.data.status) {
        setMessage(resp.data.status_msg);
      } else {
        setMessage(resp.data.status_msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Center>
      <Button
        mt={3}
        size="md"
        variant="link"
        onPress={() => {
          navigation.navigate("/");
        }}
      >
        Go to Home Page
      </Button>
      <Text
        fontFamily={GlobalStyles.text.fontFamily}
        fontSize="3xl"
        color={GlobalStyles.colors.blue}
        bold
      >
        {message && message}
      </Text>
    </Center>
  );
};

export default StripePaymentCompletePage;
