import React, { useState, useEffect, useRef } from "react";
import {
  Center,
  Stack,
  HStack,
  AspectRatio,
  Heading,
  Text,
  Box,
  Pressable,
  Modal,
  Button,
} from "native-base";
import { GlobalStyles } from "../constants/styles";
import { MaterialIcons } from "@expo/vector-icons";
import { Image } from "react-native";
import axios from "axios";
import { useSelector } from "react-redux";
import { GlobalConstants } from "../constants/constants";

const AdCard = ({ data, showViewCount, setSelectedPost }) => {
  let user = useSelector((state) => state.user.user);
  axios.defaults.headers.common = {
    Authorization: `bearer ${user ? user.token : null}`,
  };

  const [imageUrl, setImageUrl] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = (obj) => {
    setModalVisible(true);
    setImageUrl(obj.url);
  };

  const closeModal = () => {
    setImageUrl("");
    setModalVisible(false);
  };

  const updateAdViews = async (obj) => {
    console.log(obj.url);
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}/ad/1/view/increment`
      );
      if (resp.data.status) {
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to update Ads View!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    console.log(window?.innerWidth || yourDefaultWidth);
  }, []);

  return (
    <>
      {/* <Modal
        animationType="slide"
        transparent
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
      >
        <Modal.Content
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <Modal.CloseButton />
          <Modal.Header>Contact Us</Modal.Header>
          <Modal.Body>
            {imageUrl && (
              <Image
                source={{ uri: imageUrl }}
                style={{ width: 300, height: 300, resizeMode: "contain" }}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button
                variant="ghost"
                colorScheme="blueGray"
                onPress={() => {
                  setModalVisible(false);
                }}
              >
                Close
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal> */}

      <Modal
        isOpen={modalVisible}
        onClose={closeModal}
        animationType="slide"
        transparent
      >
        <Modal.Content
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          {imageUrl && (
            <Image
              source={{ uri: imageUrl }}
              style={{ width: 400, height: 400, resizeMode: "contain" }}
            />
          )}
        </Modal.Content>
      </Modal>
      <Center
        m={2}
        rounded="sm"
        _text={{
          color: "warmGray.50",
          fontWeight: "medium",
        }}
      >
        <Box alignItems="center">
          <Box
            rounded="lg"
            overflow="hidden"
            borderColor="coolGray.200"
            borderWidth="1"
            _dark={{
              borderColor: "coolGray.600",
              backgroundColor: "gray.700",
            }}
            _web={{
              shadow: 2,
              borderWidth: 0,
            }}
            _light={{
              backgroundColor: "gray.50",
            }}
          >
            <Box>
              <Pressable onPress={() => openModal(data.ad_docs[0])}>
                <AspectRatio w={[150, 150, 150]} h={[150, 150, 150]}>
                  <Image
                    source={{
                      uri: data.ad_docs[0].url,
                    }}
                    alt="image"
                  />
                </AspectRatio>
                <Center
                  bg={GlobalStyles.colors.mustard}
                  _dark={{
                    bg: "violet.400",
                  }}
                  _text={{
                    color: "warmGray.50",
                    fontWeight: "700",
                    fontSize: "xs",
                  }}
                  position="absolute"
                  bottom="0"
                  px="1"
                  py="1"
                >
                  {data.ad_category.title}
                </Center>
              </Pressable>
            </Box>

            {showViewCount ? (
              <>
                <Stack p="2" space={3}>
                  <HStack
                    alignItems="center"
                    space={2}
                    justifyContent="space-between"
                  >
                    <HStack alignItems="center">
                      <MaterialIcons
                        color={GlobalStyles.colors.mustard}
                        name="remove-red-eye"
                        size={20}
                      />
                      <Text
                        color={GlobalStyles.colors.mustard}
                        _dark={{
                          color: "warmGray.200",
                        }}
                        fontWeight="400"
                      >
                        {" " + data.view_count} Views
                      </Text>
                    </HStack>
                  </HStack>
                </Stack>
              </>
            ) : (
              <Stack p="2" w={[150, 150, 150]} h={[90, 90, 90]}>
                <Text h={[80, 80, 80]} fontWeight="200" numberOfLines={2}>
                  {data.text}
                </Text>
                <Button
                  // h={[10, 10, 10]}
                  fontSize="xs"
                  color={GlobalStyles.colors.blue}
                  alignSelf="flex-end"
                  variant="link"
                  onPress={() => {
                    setSelectedPost(data);
                  }}
                >
                  delete
                </Button>
                {/* <Pressable>
                  <Button
                    onPress={() => setSelectedPost(data)}
                    leftIcon={
                      <MaterialIcons
                        color={GlobalStyles.colors.red}
                        name="delete"
                        size={22}
                      />
                    }
                    size="sm"
                    variant="outline"
                  ></Button>
                </Pressable> */}
              </Stack>
            )}
          </Box>
        </Box>
      </Center>
    </>
  );
};

export default AdCard;
