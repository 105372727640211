import React from "react";
import { HStack, Alert, Text, Box } from "native-base";
import { GlobalStyles } from "./../constants/styles";

function Toast({ color, message }) {
  return (
    <Box
      bg={GlobalStyles.colors.white}
      px="2"
      py="1"
      rounded="sm"
      mb={5}
      borderColor={GlobalStyles.colors.mustard}
      borderWidth={2}
    >
      <HStack>
        <Alert.Icon mt={3} />
        <Text m={2} color={color} fontSize="16" fontWeight="bold">
          {message}
        </Text>
      </HStack>
    </Box>
  );
}

export default Toast;
