import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Heading,
  VStack,
  FormControl,
  Input,
  Link,
  Button,
  HStack,
  Center,
  NativeBaseProvider,
  useToast,
} from "native-base";
import { GlobalStyles } from "./../constants/styles";
import { GlobalConstants } from "./../constants/constants";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import SetUser from "../redux/user/SetUser";
import Toast from "../components/Toast";

const SignIn = () => {
  const navigation = useNavigation();
  const toast = useToast();
  const dispatch = useDispatch();

  const [payload, setPayload] = useState({
    email: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const refresh = () => {
    setPayload({
      email: "",
      password: "",
    });
  };

  function validateForm() {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (!payload.email) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"kindly enter email!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    } else if (reg.test(payload.email) === false) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"Invalid email!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    } else if (!payload.password) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"kindly enter password!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    }
    return payload;
  }

  const SignInUser = async (e) => {
    let _payload = validateForm();
    setIsLoading(true);
    console.log(_payload);
    if (_payload) {
      try {
        const resp = await axios.post(
          `${GlobalConstants.townTicksBaseUrl}login`,
          _payload
        );
        if (resp.data.status) {
          // resp.data.data.user_category = 0;// for testing
          // resp.data.data.is_new_user = true; // for testing
          dispatch(SetUser.action(resp.data.data));
          // toast.show({
          //   render: () => {
          //     return (
          //       <Toast
          //         color={GlobalStyles.colors.mustard}
          //         message={"successfully signIn..."}
          //       />
          //     );
          //   },
          //   placement: "top",
          // });
          setIsLoading(false);
          navigation.navigate("Home");
        } else {
          toast.show({
            render: () => {
              return (
                <Toast
                  color={GlobalStyles.colors.red}
                  message={resp.data.status_msg}
                />
              );
            },
            placement: "top",
          });
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <Center w="100%">
      <Box safeArea p="2" py="8" w="90%" maxW="290">
        {/* <Heading
          size="lg"
          color={GlobalStyles.colors.blue}
          fontFamily={GlobalStyles.text.fontFamily}
          fontSize="2xl"
          fontWeight="bold"
        >
          Sign In
        </Heading>
        <Heading
          mt="1"
          _dark={{
            color: "warmGray.200",
          }}
          color="coolGray.600"
          fontWeight="medium"
          size="xs"
        >
          Sign in to continue!
        </Heading> */}

        <VStack space={3} mt="5">
          <FormControl>
            <FormControl.Label>Email</FormControl.Label>
            <Input
              onChange={(x) => {
                const _payload = { ...payload };
                _payload.email = x.target.value;
                setPayload(_payload);
              }}
            />
          </FormControl>
          <FormControl>
            <FormControl.Label>Password</FormControl.Label>
            <Input
              type="password"
              onChange={(x) => {
                const _payload = { ...payload };
                _payload.password = x.target.value;
                setPayload(_payload);
              }}
            />
            <Link
              _text={{
                fontSize: "xs",
                fontWeight: "500",
                color: "indigo.500",
              }}
              alignSelf="flex-end"
              mt="1"
            >
              Forget Password?
            </Link>
          </FormControl>
          <Button
            isLoading={isLoading}
            mt="2"
            size="sm"
            variant={"solid"}
            px="3"
            bg={GlobalStyles.colors.blue}
            borderColor={GlobalStyles.colors.mustard}
            borderWidth={2}
            onPress={SignInUser}
          >
            Sign in
          </Button>
          {/* <HStack mt="6" justifyContent="center">
            <Text
              fontSize="sm"
              color="coolGray.600"
              _dark={{
                color: "warmGray.200",
              }}
            >
              I'm a new user.{" "}
            </Text>
            <Link
              _text={{
                color: "indigo.500",
                fontWeight: "medium",
                fontSize: "sm",
              }}
              href="#"
            >
              Sign Up
            </Link>
          </HStack> */}
        </VStack>
      </Box>
    </Center>
  );
};

export default SignIn;
