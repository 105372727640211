// PaymentPage.js
import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeForm from "./StripeForm";
import { GlobalConstants } from "../constants/constants";

const stripePromise = loadStripe(GlobalConstants.stripePublicKey);

const StripeCard = (props) => {
  console.log(props.options);
  return (
    <Elements stripe={stripePromise} options={props.options}>
      <StripeForm IntentPayload={props.IntentPayload} />
    </Elements>
  );
};

export default StripeCard;
