import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Heading,
  VStack,
  FormControl,
  Input,
  Link,
  Button,
  HStack,
  Center,
  NativeBaseProvider,
  useToast,
  Select,
} from "native-base";
import { GlobalStyles } from "./../constants/styles";
import { GlobalConstants } from "./../constants/constants";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import SetUser from "../redux/user/SetUser";
import Toast from "../components/Toast";

const SignInGuest = () => {
  const navigation = useNavigation();
  const toast = useToast();
  const dispatch = useDispatch();

  const [payload, setPayload] = useState({
    city_id: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState([]);

  const getAllCities = async () => {
    try {
      const resp = await axios.get(`${GlobalConstants.townTicksBaseUrl}cities`);
      if (resp.data.status) {
        setCities(resp.data.data);
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to get cities!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const refresh = () => {
    setPayload({
      city_id: "",
    });
  };

  function validateForm() {
    if (!payload.city_id) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"please select city!!!"}
            />
          );
        },
        placement: "top",
      });
      setIsLoading(false);
      return false;
    }
    return payload;
  }

  const SignInGuestUser = async (e) => {
    setIsLoading(true);
    let _payload = validateForm();
    console.log(_payload);
    if (_payload) {
      try {
        const resp = await axios.post(
          `${GlobalConstants.townTicksBaseUrl}guest-login`,
          _payload
        );
        if (resp.data.status) {
          dispatch(SetUser.action(resp.data.data));
          setIsLoading(false);
          navigation.navigate("Home");
        } else {
          toast.show({
            render: () => {
              return (
                <Toast
                  color={GlobalStyles.colors.red}
                  message={resp.data.status_msg}
                />
              );
            },
            placement: "top",
          });
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(async () => {
    refresh();
    await getAllCities();
  }, []);

  return (
    <Center w="100%">
      <Box safeArea p="2" py="8" w="90%" maxW="290">
        <VStack space={3} mt="5">
          <FormControl>
            <FormControl.Label>City</FormControl.Label>
            <Select
              selectedValue={payload.city_id}
              minWidth="200"
              accessibilityLabel="Choose City"
              placeholder="Choose City"
              _selectedItem={{
                bg: "teal.600",
              }}
              mt="1"
              onValueChange={(x) => {
                const _payload = { ...payload };
                _payload.city_id = x;
                setPayload(_payload);
              }}
            >
              {cities.map((x, index) => {
                return (
                  <Select.Item
                    key={index}
                    label={x.title.toString()}
                    value={x.id.toString()}
                  />
                );
              })}
            </Select>
          </FormControl>
          <Button
            isLoading={isLoading}
            mt="2"
            size="sm"
            variant={"solid"}
            px="3"
            bg={GlobalStyles.colors.blue}
            borderColor={GlobalStyles.colors.mustard}
            borderWidth={2}
            onPress={SignInGuestUser}
          >
            Sign in
          </Button>
        </VStack>
      </Box>
    </Center>
  );
};

export default SignInGuest;
