import React, { useState, useCallback } from "react";
import { View, Button } from "native-base";
import { DatePickerModal } from "react-native-paper-dates";
import { SafeAreaProvider } from "react-native-safe-area-context";

const DateRangePickerModel = (props) => {
  const [range, setRange] = useState({
    startDate: undefined,
    endDate: undefined,
  });

  const [open, setOpen] = useState(true);

  const onDismiss = useCallback(() => {
    setOpen(false);
    props.onClose(false);
  }, [setOpen]);

  const onConfirm = useCallback(
    ({ startDate, endDate }) => {
      setOpen(false);
      setRange({ startDate, endDate });
      console.log(startDate);
      props.onChange(convertDate(startDate), convertDate(endDate));
    },
    [setOpen, setRange]
  );

  const convertDate = (inputFormat) => {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join("/");
  };

  return (
    <View mt={5}>
      <DatePickerModal
        locale="en-GB"
        mode="range"
        visible={open}
        onDismiss={onDismiss}
        startDate={range.startDate}
        endDate={range.endDate}
        onConfirm={onConfirm}
      />
    </View>
  );
};

export default DateRangePickerModel;
