import * as Font from 'expo-font';

const LoadFonts = async () => {
  await Font.loadAsync({
    // You can load as many fonts as you need
    'Petemoss': require('../../assets/Petemoss-Regular.ttf'),
    // Add more fonts if necessary
  });
};

export default LoadFonts;