import React, { useEffect, useState } from "react";
import { Center, ScrollView, Text, Box, HStack, Button } from "native-base";
import { useNavigation } from "@react-navigation/native";
import { useSelector, useDispatch } from "react-redux";
import { GlobalConstants } from "../constants/constants";
import CitizenSettingsPage from "./settings/CitizenSettingsPage";
import BusinessSettingsPage from "./settings/BusinessSettingsPage";
import CityGovSettingsPage from "./settings/CityGovSettingsPage";
import ChamberOfCommerceSettingsPage from "./settings/ChamberOfCommerceSettingsPage";
import EventSettingsPage from "./settings/EventSettingsPage";
import SetUser from "../redux/user/SetUser";
import axios from "axios";
import ConfigureSettingsPopup from "../components/ConfigureSettingsPopup";
import AdvertiserSettingsPage from "./settings/AdvertiserSettingsPage";

function Settings() {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  let user = useSelector((state) => state.user.user);

  axios.defaults.headers.common = {
    Authorization: `bearer ${user ? user.token : null}`,
  };

  const [session, setSession] = useState(user);

  const updateIsNewUserFlag = async () => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}users/flag/new`,
        {}
      );
      if (resp.data.status) {
        let data = { ...session };
        dispatch(SetUser.action(null));
        data.is_new_user = false;
        dispatch(SetUser.action(data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = React.useCallback(async (value) => {
    await updateIsNewUserFlag();
  }, []);

  useEffect(async () => {}, []);

  return (
    <>
      <Center>
        <HStack bg="#F5F5F5" alignItems="center" w="100%" maxW="900">
          <Button
            alignSelf={"start"}
            size="sm"
            variant="link"
            onPress={() => {
              navigation.navigate("Home");
            }}
          >
            Go to Home Page
          </Button>
        </HStack>
      </Center>

      <Center>
        <Box safeAreaTop bg="white" />
        <HStack
          bg="#F5F5F5"
          px="1"
          py="5"
          justifyContent="space-between"
          alignItems="center"
          w="100%"
          maxW="900"
        >
          {user &&
          user.user_category == GlobalConstants.userCategories.citizen.id ? (
            <CitizenSettingsPage />
          ) : user &&
            user.user_category == GlobalConstants.userCategories.business.id ? (
            <BusinessSettingsPage />
          ) : user &&
            user.user_category ==
              GlobalConstants.userCategories.cityGovernment.id ? (
            <CityGovSettingsPage />
          ) : user &&
            user.user_category ==
              GlobalConstants.userCategories.chamberOfCommerce.id ? (
            <ChamberOfCommerceSettingsPage />
          ) : user &&
            user.user_category ==
              GlobalConstants.userCategories.advertiser.id ? (
            <AdvertiserSettingsPage />
          ) : user &&
            user.user_category ==
              GlobalConstants.userCategories.eventOrganizer.id ? (
            <EventSettingsPage />
          ) : (
            <>
              <ScrollView>
                <Center>
                  <Text mt={10}>No Settings</Text>
                </Center>
              </ScrollView>
            </>
          )}
        </HStack>
      </Center>
      <ConfigureSettingsPopup
        heading="Complete Profile"
        body="You are in settings. 'Click Go to Home' to go to the main page to see ticks."
        data={session.is_new_user}
        onChange={handleChange}
      />
    </>
  );
}

export default Settings;
