import React from "react";
import { Box, Center, Text, HStack, ScrollView, Button } from "native-base";
import { GlobalStyles } from "../constants/styles";
import { useNavigation } from "@react-navigation/native";

const WhyTownTicks = () => {
  const navigation = useNavigation();

  return (
    <>
      <ScrollView>
        <Center>
          <HStack bg="#F5F5F5" alignItems="center" w="100%" maxW="700">
            <Button
              alignSelf={"start"}
              size="sm"
              variant="link"
              onPress={() => {
                navigation.navigate("SignUp");
              }}
            >
              Back to Sign Up Page
            </Button>
          </HStack>
        </Center>
        <Center>
          <Box safeAreaTop bg="white" />
          <HStack
            bg="#F5F5F5"
            px="1"
            justifyContent="space-between"
            alignItems="center"
            w="100%"
            maxW="700"
          >
            <Center h={[500, 250, 250]}>
              <Center h={[100, 100, 100]} w={[300, 450, 700]}>
                <Text
                  fontSize="xl"
                  fontWeight="bold"
                  fontFamily={GlobalStyles.text.fontFamily}
                >
                  We need to verify the identity of your Business. To register
                  with us please send an email with your official email address
                  on this email id:
                  <Text color={GlobalStyles.colors.blue}>
                    {" "}
                    townticks@novelma.com
                  </Text>
                </Text>
              </Center>
            </Center>
          </HStack>
        </Center>
      </ScrollView>
    </>
  );
};

export default WhyTownTicks;
