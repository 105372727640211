export const GlobalStyles = {
  colors: {
    blue: "#002061",
    mustard: "#FFC000",
    white: "#FFFFFF",
    black: "#000000",
    red: "#cc3300",
    grey: "#E5E4E2",
    lightGrey: "#F5F4F2",
    green: "#4BB543",
  },
  text: {
    fontFamily: "Merriweather",
  },
};
