import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { View, Button, Center, Text } from "native-base";
import { useSelector } from "react-redux";
import axios from "axios";
import { GlobalStyles } from "../constants/styles";
import { GlobalConstants } from "../constants/constants";

const StripeForm = (props) => {
  let user = useSelector((state) => state.user.user);
  axios.defaults.headers.common = {
    Authorization: `bearer ${user ? user.token : null}`,
  };

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const resp = await axios.post(
      `${GlobalConstants.townTicksBaseUrl}payment/secret`,
      props.IntentPayload
    );

    if (resp.data.status) {
      const clientSecret = resp.data.data.secret;
      console.log(clientSecret);
      const { error } = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        clientSecret,
        confirmParams: {
          return_url:
            "http://localhost:19006/payment/" + resp.data.data.id + "/complete",
        },
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        setErrorMessage(error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    } else {
      setErrorMessage(resp.data.status_msg);
    }
  };

  return (
    <Center>
      <View>
        <PaymentElement />
      </View>
      <Button
        size="sm"
        variant={"solid"}
        px="3"
        bg={GlobalStyles.colors.blue}
        borderColor={GlobalStyles.colors.mustard}
        borderWidth={2}
        onPress={handleSubmit}
        isDisabled={!stripe || !elements}
        m={3}
      >
        <Text color={GlobalStyles.colors.white}>Pay</Text>
      </Button>
      {errorMessage && (
        <Text color={GlobalStyles.colors.red}>{errorMessage}</Text>
      )}
    </Center>
  );
};

export default StripeForm;
