import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Heading,
  VStack,
  FormControl,
  Input,
  Button,
  Center,
  NativeBaseProvider,
  Select,
  CheckIcon,
  Modal,
  Divider,
  Text,
  ScrollView,
  useToast,
  Alert,
  HStack,
  Icon,
  Pressable,
} from "native-base";
import { GlobalStyles } from "../constants/styles";
import { GlobalConstants } from "../constants/constants";
import { uploadImage } from "../utils/general";
import { useNavigation } from "@react-navigation/native";
import Toast from "../components/Toast";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import axios from "axios";
import ConfigureSettingsPopup from "../components/ConfigureSettingsPopup";

const SignUp = () => {
  // for testing

  const toast = useToast();
  const navigation = useNavigation();

  // const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [businessCategories, setBusinessCategories] = useState([]);
  const [businessSubCategories, setBusinessSubCategories] = useState([]);
  const [registrationCode, setRegistrationCode] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [submitPopUp, setSubmitPopUp] = useState(false);

  const [payload, setPayload] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    category_id: "",
    city_id: "",
    business_sub_category: 0,
    name: "",
    logo_url: "",
  });

  const refresh = () => {
    setPayload({
      email: "",
      password: "",
      confirmPassword: "",
      category_id: "",
      city_id: "",
      business_sub_category: 0,
      name: "",
      logo_url: "",
    });
  };

  function validateForm() {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (!payload.email) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"please enter email!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    } else if (reg.test(payload.email) === false) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"Invalid email!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    } else if (!payload.password) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"please enter password!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    } else if (!payload.confirmPassword) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"please enter confirm password!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    } else if (payload.password !== payload.confirmPassword) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"Invalid confirm password!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    } else if (!payload.city_id) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"please select city!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    } else if (!payload.category_id) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"please select user category!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    } else if (
      payload.category_id == GlobalConstants.userCategories.business.id &&
      !payload.business_sub_category
    ) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"please select business category & sub category!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    } else if (!payload.name) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={
                payload.category_id == GlobalConstants.userCategories.citizen.id
                  ? "please enter profile name!!!"
                  : "please enter official name!!!"
              }
            />
          );
        },
        placement: "top",
      });
      return false;
    } else if (
      payload.category_id != GlobalConstants.userCategories.citizen.id &&
      payload.category_id != GlobalConstants.userCategories.business.id &&
      registrationCode != GlobalConstants.regCode
    ) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={
                !registrationCode
                  ? "please enter registration code!!!"
                  : "please enter valid registration code!!!"
              }
            />
          );
        },
        placement: "top",
      });
      return false;
    }
    return payload;
  }

  const SignUp = async (e) => {
    let _payload = validateForm();
    delete _payload.confirmPassword;
    _payload.category_id = parseInt(_payload.category_id);
    console.log(_payload);
    if (_payload) {
      try {
        const resp = await axios.post(
          `${GlobalConstants.townTicksBaseUrl}register`,
          _payload
        );
        if (resp.data.status) {
          refresh();
          toast.show({
            render: () => {
              return (
                <Toast
                  color={GlobalStyles.colors.mustard}
                  message={"successfully registered!!!"}
                />
              );
            },
            placement: "top",
          });
          navigation.navigate("SignIn");
          // setShowModal(true);
        } else {
          toast.show({
            render: () => {
              return (
                <Toast
                  color={GlobalStyles.colors.red}
                  message={resp.data.status_msg}
                />
              );
            },
            placement: "top",
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getAllCategories = async () => {
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}users/category`
      );
      if (resp.data.status) {
        setCategories(resp.data.data);
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to get categories!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCities = async () => {
    try {
      const resp = await axios.get(`${GlobalConstants.townTicksBaseUrl}cities`);
      if (resp.data.status) {
        setCities(resp.data.data);
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to get cities!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllBusinessCategories = async () => {
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}businesses/category`
      );
      if (resp.data.status) {
        setBusinessCategories(resp.data.data);
        navigation.navigate("RegisterAsBusiness");
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to get business categories!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllBusinessSubCategoriesById = async (type) => {
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}businesses/subcategory/` + type
      );
      if (resp.data.status) {
        setBusinessSubCategories(resp.data.data);
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to business sub categories!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function selectFile() {
    setIsLoading(true);
    let imageUrl = await uploadImage();
    if (imageUrl) {
      const _payload = { ...payload };
      _payload.logo_url = imageUrl;
      setPayload(_payload);
      setIsLoading(false);
    } else {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"please check image size should be less than 500KB!!!"}
            />
          );
        },
        placement: "top",
      });
      setIsLoading(false);
    }
  }

  useEffect(async () => {
    refresh();
    await getAllCategories();
    await getAllCities();
  }, []);

  const handleChange = useCallback(async (value) => {
    setSubmitPopUp(false);
  }, []);

  return (
    <>
      <ScrollView>
        <Box alignItems="center">
          <Center w="100%">
            <Box safeArea p="2" w="90%" maxW="290" py="8">
              {/* <Heading
                size="lg"
                color={GlobalStyles.colors.blue}
                fontFamily={GlobalStyles.text.fontFamily}
                fontSize="2xl"
                fontWeight="bold"
              >
                Create Account
              </Heading>
              <Heading
                mt="1"
                color="coolGray.600"
                _dark={{
                  color: "warmGray.200",
                }}
                fontWeight="medium"
                size="xs"
              >
                Sign up to continue!
                <Button
                  size="sm"
                  variant="link"
                  onPress={() => {
                    navigation.navigate("RegisterAsBusiness");
                  }}
                >
                  Register as a Business
                </Button>
              </Heading> */}
              <VStack space={3} mt="5">
                <FormControl>
                  <FormControl.Label>Select Your Type</FormControl.Label>
                  <Select
                    selectedValue={payload.category_id}
                    minWidth="200"
                    accessibilityLabel="Choose User Type"
                    placeholder="Choose User Type"
                    _selectedItem={{
                      bg: "teal.600",
                    }}
                    mt="1"
                    onValueChange={(x) => {
                      let _payload = { ...payload };
                      _payload.category_id = x;
                      setPayload(_payload);
                      if (x == GlobalConstants.userCategories.business.id) {
                        getAllBusinessCategories();
                      } else {
                        setBusinessCategories([]);
                        setBusinessSubCategories([]);
                      }
                    }}
                  >
                    {categories.map((x, index) => {
                      return (
                        <Select.Item
                          key={index}
                          label={x.title.toString()}
                          value={x.id.toString()}
                        />
                      );
                    })}
                  </Select>
                </FormControl>

                {payload.category_id ? (
                  <>
                    <FormControl>
                      <FormControl.Label>Email</FormControl.Label>
                      <Input
                        value={payload.email}
                        onChange={(x) => {
                          let _payload = { ...payload };
                          _payload.email = x.target.value;
                          setPayload(_payload);
                        }}
                      />
                    </FormControl>

                    <FormControl>
                      <FormControl.Label>Password</FormControl.Label>
                      <Input
                        value={payload.password}
                        type="password"
                        onChange={(x) => {
                          let _payload = { ...payload };
                          _payload.password = x.target.value;
                          setPayload(_payload);
                        }}
                      />
                    </FormControl>

                    <FormControl>
                      <FormControl.Label>Confirm Password</FormControl.Label>
                      <Input
                        value={payload.confirmPassword}
                        type="password"
                        onChange={(x) => {
                          let _payload = { ...payload };
                          _payload.confirmPassword = x.target.value;
                          setPayload(_payload);
                        }}
                      />
                    </FormControl>

                    <FormControl>
                      <FormControl.Label>City</FormControl.Label>
                      <Select
                        selectedValue={payload.city_id}
                        minWidth="200"
                        accessibilityLabel="Choose City"
                        placeholder="Choose City"
                        _selectedItem={{
                          bg: "teal.600",
                        }}
                        mt="1"
                        onValueChange={(x) => {
                          const _payload = { ...payload };
                          _payload.city_id = x;
                          setPayload(_payload);
                        }}
                      >
                        {cities.map((x, index) => {
                          return (
                            <Select.Item
                              key={index}
                              label={x.title.toString()}
                              value={x.id.toString()}
                            />
                          );
                        })}
                      </Select>
                    </FormControl>

                    <FormControl>
                      <FormControl.Label>
                        {payload.category_id ==
                          GlobalConstants.userCategories.citizen.id ||
                        payload.category_id == ""
                          ? "Profile Name"
                          : "Official Name"}
                      </FormControl.Label>
                      <Input
                        value={payload.name}
                        onChange={(x) => {
                          let _payload = { ...payload };
                          _payload.name = x.target.value;
                          setPayload(_payload);
                        }}
                      />
                    </FormControl>
                  </>
                ) : (
                  <></>
                )}

                {payload.category_id ==
                GlobalConstants.userCategories.business.id ? (
                  <Box>
                    <FormControl>
                      <FormControl.Label>Business Category</FormControl.Label>
                      <Select
                        minWidth="200"
                        accessibilityLabel="Choose Business Category"
                        placeholder="Choose Business Category"
                        _selectedItem={{
                          bg: "teal.600",
                        }}
                        mt="1"
                        onValueChange={(x) => {
                          getAllBusinessSubCategoriesById(x);
                        }}
                      >
                        {businessCategories.map((x, index) => {
                          return (
                            <Select.Item
                              key={index}
                              label={x.title.toString()}
                              value={x.id.toString()}
                            />
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormControl.Label>
                        Business Sub Category
                      </FormControl.Label>
                      <Select
                        selectedValue={payload.business_sub_category}
                        minWidth="200"
                        accessibilityLabel="Choose Business Sub Category"
                        placeholder="Choose Business Sub Category"
                        _selectedItem={{
                          bg: "teal.600",
                        }}
                        mt="1"
                        onValueChange={(x) => {
                          let _payload = { ...payload };
                          _payload.business_sub_category = x;
                          setPayload(_payload);
                        }}
                      >
                        {businessSubCategories.map((x, index) => {
                          return (
                            <Select.Item
                              key={index}
                              label={x.title.toString()}
                              value={x.id.toString()}
                            />
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  <></>
                )}

                {payload.category_id ==
                  GlobalConstants.userCategories.citizen.id ||
                payload.category_id ==
                  GlobalConstants.userCategories.business.id ||
                payload.category_id == "" ? (
                  <></>
                ) : (
                  <>
                    <FormControl>
                      <FormControl.Label>Registration Code*</FormControl.Label>
                      <Input
                        value={registrationCode}
                        onChange={(x) => {
                          setRegistrationCode(x.target.value);
                        }}
                        InputRightElement={
                          <Button
                            onPress={() => {
                              setSubmitPopUp(true);
                            }}
                            bgColor={GlobalStyles.colors.grey}
                            _loading={{
                              bg: "muted.500",
                            }}
                            _spinner={{
                              color: "white",
                            }}
                          >
                            <Icon
                              size="6"
                              color="muted.500"
                              as={<MaterialIcons name="info-outline" />}
                            />
                          </Button>
                        }
                      />
                    </FormControl>

                    <FormControl>
                      <FormControl.Label>
                        Upload Logo (Optional) Limit:500KB
                      </FormControl.Label>
                      <Pressable
                        onPress={() => {
                          selectFile();
                        }}
                      >
                        <Input
                          value={payload.logo_url ? payload.logo_url : ""}
                          InputRightElement={
                            <Button
                              onPress={() => {
                                selectFile();
                              }}
                              isLoading={isLoading}
                              bgColor={GlobalStyles.colors.grey}
                              _loading={{
                                bg: "muted.500",
                              }}
                              _spinner={{
                                color: "white",
                              }}
                            >
                              <Icon
                                size="6"
                                color="muted.500"
                                as={<MaterialIcons name="file-upload" />}
                              />
                            </Button>
                          }
                        />
                      </Pressable>
                    </FormControl>
                  </>
                )}
                <Button
                  mt="2"
                  size="sm"
                  variant={"solid"}
                  px="3"
                  bg={GlobalStyles.colors.blue}
                  borderColor={GlobalStyles.colors.mustard}
                  borderWidth={2}
                  onPress={SignUp}
                >
                  Sign up
                </Button>

                {submitPopUp ? (
                  <ConfigureSettingsPopup
                    heading="Registration Code"
                    body="We need to verify the identity of your Business. To register with us please send an email with your official email address on this email id: townticks@novelma.com"
                    data={submitPopUp}
                    onChange={handleChange}
                  />
                ) : (
                  <></>
                )}
              </VStack>
            </Box>
          </Center>
        </Box>
      </ScrollView>
    </>
  );
};

export default SignUp;
