import React, { useState, useEffect } from "react";
import { AlertDialog, Button, Center, Text } from "native-base";
import { GlobalStyles } from "../constants/styles";
import StripeCard from "./StripeCard";

const StripePopup = (props) => {
  const [isOpen, setIsOpen] = useState(props.data);

  const onClose = () => {
    setIsOpen(false);
    props.onChange(false);
  };

  const onOk = () => {
    setIsOpen(false);
    props.onChange(true);
  };

  const options = {
    mode: "payment",
    amount: props.body.amount,
    currency: "usd",
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  const cancelRef = React.useRef(null);
  return (
    <Center>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>{props.heading}</AlertDialog.Header>
          <AlertDialog.Body>
            <StripeCard options={options} IntentPayload={props.body} />
          </AlertDialog.Body>
        </AlertDialog.Content>
      </AlertDialog>
    </Center>
  );
};

export default StripePopup;
