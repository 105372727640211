import React from "react";
import { NavigationContainer } from "@react-navigation/native";
// import { createWebStackNavigator } from "react-navigation/web";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import Header from "../components/Header";
import Home from "../screens/Home";
import SignUp from "../screens/SignUp";
import SignIn from "../screens/SignIn";
import SignInGuest from "../screens/SignInGuest";
import WhyTownTicks from "../screens/WhyTownTicks";
import RegisterAsBusiness from "../screens/RegisterAsBusiness";
import CitizenMainPage from "../screens/main/CitizenMainPage";
import BusinessMainPage from "../screens/main/BusinessMainPage";
import CityGovMainPage from "../screens/main/CityGovMainPage";
import EventMainPage from "../screens/main/EventMainPage";
import Settings from "../screens/Settings";
import ChamberOfCommerceMainPage from "../screens/main/ChamberOfCommerceMainPage";
import AdvertiserMainPage from "../screens/main/AdvertiserMainPage";
import CitizenSettingsPage from "../screens/settings/CitizenSettingsPage";
import BusinessSettingsPage from "../screens/settings/BusinessSettingsPage";
import AdvertiserSettingsPage from "../screens/settings/AdvertiserSettingsPage";
import ChamberOfCommerceSettingsPage from "../screens/settings/ChamberOfCommerceSettingsPage";
import CityGovSettingsPage from "../screens/settings/CityGovSettingsPage";
import EventSettingsPage from "../screens/settings/EventSettingsPage";
import CitizenAdsPage from "../screens/main/CitizenAdsPage";
import AdInviteLandingPage from "../screens/main/AdInviteLandingPage";
import FeedInviteLandingPage from "../screens/main/FeedInviteLandingPage";
import StripePaymentCompletePage from "../screens/main/StripePaymentCompletePage";


const Stack = createNativeStackNavigator();

const AppNavigation = () => {
  return (
    <NavigationContainer>
    <Header m="5" />
    <Router>
      <Routes>
        <Route
          path="/invite/ad/:id"
          element={
            <Stack.Navigator initialRouteName="AdInviteLandingPage">
              <Stack.Screen
                name="Home"
                component={Home}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="SignUp"
                component={SignUp}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="SignIn"
                component={SignIn}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="SignInGuest"
                component={SignInGuest}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="WhyTownTicks"
                component={WhyTownTicks}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="RegisterAsBusiness"
                component={RegisterAsBusiness}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CitizenMainPage"
                component={CitizenMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="BusinessMainPage"
                component={BusinessMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CityGovMainPage"
                component={CityGovMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="EventMainPage"
                component={EventMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="ChamberOfCommerceMainPage"
                component={ChamberOfCommerceMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="AdvertiserMainPage"
                component={AdvertiserMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CitizenAdsPage"
                component={CitizenAdsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CitizenSettingsPage"
                component={CitizenSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="AdvertiserSettingsPage"
                component={AdvertiserSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="BusinessSettingsPage"
                component={BusinessSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="ChamberOfCommerceSettingsPage"
                component={ChamberOfCommerceSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CityGovSettingsPage"
                component={CityGovSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="EventSettingsPage"
                component={EventSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="Settings"
                component={Settings}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="AdInviteLandingPage"
                component={AdInviteLandingPage}
                options={{ headerShown: false }}
              />
            </Stack.Navigator>
          }
        />
        <Route
          path="/invite/feed/:id"
          element={
            <Stack.Navigator initialRouteName="FeedInviteLandingPage">
              <Stack.Screen
                name="Home"
                component={Home}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="SignUp"
                component={SignUp}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="SignIn"
                component={SignIn}
                options={{ headerShown: false }}
              />
               <Stack.Screen
                name="SignInGuest"
                component={SignInGuest}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="WhyTownTicks"
                component={WhyTownTicks}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="RegisterAsBusiness"
                component={RegisterAsBusiness}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CitizenMainPage"
                component={CitizenMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="BusinessMainPage"
                component={BusinessMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CityGovMainPage"
                component={CityGovMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="EventMainPage"
                component={EventMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="ChamberOfCommerceMainPage"
                component={ChamberOfCommerceMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="AdvertiserMainPage"
                component={AdvertiserMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CitizenAdsPage"
                component={CitizenAdsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CitizenSettingsPage"
                component={CitizenSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="AdvertiserSettingsPage"
                component={AdvertiserSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="BusinessSettingsPage"
                component={BusinessSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="ChamberOfCommerceSettingsPage"
                component={ChamberOfCommerceSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CityGovSettingsPage"
                component={CityGovSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="EventSettingsPage"
                component={EventSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="Settings"
                component={Settings}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="FeedInviteLandingPage"
                component={FeedInviteLandingPage}
                options={{ headerShown: false }}
              />
            </Stack.Navigator>
          }
        />
         <Route
          path="/payment/:id/complete"
          element={
            <Stack.Navigator initialRouteName="StripePaymentCompletePage">
              <Stack.Screen
                name="Home"
                component={Home}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="SignUp"
                component={SignUp}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="SignIn"
                component={SignIn}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="SignInGuest"
                component={SignInGuest}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="WhyTownTicks"
                component={WhyTownTicks}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="RegisterAsBusiness"
                component={RegisterAsBusiness}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CitizenMainPage"
                component={CitizenMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="BusinessMainPage"
                component={BusinessMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CityGovMainPage"
                component={CityGovMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="EventMainPage"
                component={EventMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="ChamberOfCommerceMainPage"
                component={ChamberOfCommerceMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="AdvertiserMainPage"
                component={AdvertiserMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CitizenAdsPage"
                component={CitizenAdsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CitizenSettingsPage"
                component={CitizenSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="AdvertiserSettingsPage"
                component={AdvertiserSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="BusinessSettingsPage"
                component={BusinessSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="ChamberOfCommerceSettingsPage"
                component={ChamberOfCommerceSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CityGovSettingsPage"
                component={CityGovSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="EventSettingsPage"
                component={EventSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="Settings"
                component={Settings}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="StripePaymentCompletePage"
                component={StripePaymentCompletePage}
                options={{ headerShown: false }}
              />
            </Stack.Navigator>
          }
        />
        <Route
          path="/"
          element={
            <Stack.Navigator initialRouteName="Home">
              <Stack.Screen
                name="Home"
                component={Home}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="SignUp"
                component={SignUp}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="SignIn"
                component={SignIn}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="SignInGuest"
                component={SignInGuest}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="WhyTownTicks"
                component={WhyTownTicks}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="RegisterAsBusiness"
                component={RegisterAsBusiness}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CitizenMainPage"
                component={CitizenMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="BusinessMainPage"
                component={BusinessMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CityGovMainPage"
                component={CityGovMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="EventMainPage"
                component={EventMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="ChamberOfCommerceMainPage"
                component={ChamberOfCommerceMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="AdvertiserMainPage"
                component={AdvertiserMainPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CitizenAdsPage"
                component={CitizenAdsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CitizenSettingsPage"
                component={CitizenSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="AdvertiserSettingsPage"
                component={AdvertiserSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="BusinessSettingsPage"
                component={BusinessSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="ChamberOfCommerceSettingsPage"
                component={ChamberOfCommerceSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="CityGovSettingsPage"
                component={CityGovSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="EventSettingsPage"
                component={EventSettingsPage}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name="Settings"
                component={Settings}
                options={{ headerShown: false }}
              />
            </Stack.Navigator>
          }
        />
      </Routes>
    </Router>
  </NavigationContainer>
  );
};

export default AppNavigation ;
