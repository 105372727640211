import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GlobalConstants } from "../../constants/constants";
import axios from "axios";
import { Box, Stack, Text, useToast } from "native-base";
import AdCardForCitizen from "../../components/AdCardForCitizen";
import { useSelector } from "react-redux";
import Toast from "../../components/Toast";
import { GlobalStyles } from "../../constants/styles";

const AdInviteLandingPage = () => {
  const toast = useToast();
  let user = useSelector((state) => state.user.user);
  axios.defaults.headers.common = {
    Authorization: `bearer ${user ? user.token : null}`,
  };
  console.log(user);

  const { id } = useParams();

  const [selectedPost, setSelectedPost] = useState(null);

  const getAdById = async () => {
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}ad/` + id
      );
      if (resp.data.status) {
        console.log(resp.data.data);
        var data = resp.data.data;
        data.savedAllowed = user ? true : false;
        // data.inviteUrl = window.location.host + "/invite/ad/" + data.id;
        setSelectedPost(data);
      } else {
        setSelectedPost(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClick = async (data) => {
    if (data.isSave) {
      await saveAd(data.id);
    } else {
      await unSaveAd(data.id);
    }
  };

  const unSaveAd = async (adId) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}ad/unsave`,
        {
          ad_id: adId,
        }
      );
      if (resp.data.status) {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"Ad unsaved scuccessfully!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to unsave ad!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveAd = async (adId) => {
    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}ad/save`,
        {
          ad_id: adId,
        }
      );
      if (resp.data.status) {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"Ad saved scuccessfully!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to save ad!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    console.log("////////////////////////////////////////////////////");
    console.log(window?.innerWidth || yourDefaultWidth);
    await getAdById();
  }, []);

  return (
    <ScrollView>
      <Center bg={GlobalStyles.colors.lightGrey}>
        <Box>
          <Stack
            justifyContent="center"
            flexDirection={"row"}
            space={3}
            flexWrap="wrap"
            m={2}
          >
            {selectedPost ? (
              <AdCardForCitizen data={selectedPost} setSelectedPost={onClick} />
            ) : (
              <Text mt={5}>No Record Found</Text>
            )}
          </Stack>
        </Box>
      </Center>
    </ScrollView>
  );
};

export default AdInviteLandingPage;
