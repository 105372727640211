export const GlobalConstants = {
  googleTrackingId: "G-Z3VY4GF3N9",
  notFoundImageUrl: "https://test.jpg/",
  noImageUrl: "https://towntick-stage.s3.ca-central-1.amazonaws.com/1702810777690.png",
  testingName: "Charity Walk",
  townTicksBaseUrl: "https://api.townticks.com/", // for production
  // townTicksBaseUrl:
  //   "http://ec2-35-183-39-131.ca-central-1.compute.amazonaws.com/", // for stagaing
  regCode: "888900",
  stripePublicKey: "pk_live_51OjecxL0vsXU4hw9blOwBW4gOIJvRE6MF9IaKMr3qiC921ixTM37DzFQFEjCo6lFLTxsMW7ZY5uEWHAEr0A7vqhr00oGV1VnBa",

  userCategories: {
    guest: {
      id: 0,
      title: "GuestUser",
    },
    citizen: {
      id: 1,
      title: "Citizen",
    },
    business: {
      id: 2,
      title: "Business",
    },
    cityGovernment: {
      id: 3,
      title: "City Government",
    },
    chamberOfCommerce: {
      id: 4,
      title: "Chamber of Commerce",
    },
    eventOrganizer: {
      id: 5,
      title: "Event Organizer",
    },
    advertiser: {
      id: 6,
      title: "Advertiser",
    },
  },
  feedTypes: {
    business: {
      id: 1,
      title: "BUSINESS",
    },
    cityGovernment: {
      id: 2,
      title: "CITY_GOVERNMENT",
    },
    events: {
      id: 3,
      title: "EVENTS",
    },
    openings: {
      id: 4,
      title: "OPENINGS",
    },
    ads: {
      id: 5,
      title: "ADS",
    },
    deals: {
      id: 6,
      title: "DEALS",
    },
  },

  postCategories: {
    tick: {
      id: 1,
      title: "TICK",
    },
    ad: {
      id: 2,
      title: "AD",
    },
    jobOpening: {
      id: 3,
      title: "JOB_OPENING",
    },
    event: {
      id: 4,
      title: "EVENT",
    },
    deal: {
      id: 5,
      title: "DEAL",
    },
  },

  AdCategories: {
    product: {
      id: 1,
      title: "Product",
    },
    informative: {
      id: 2,
      title: "Informative",
    },
    publicService: {
      id: 3,
      title: "Public Service",
    },
    political: {
      id: 4,
      title: "Political",
    },
    brands: {
      id: 6,
      title: "Brands",
    },
    others: {
      id: 7,
      title: "Others",
    },
  },
};
