import { GlobalConstants } from "../constants/constants";
import * as DocumentPicker from "expo-document-picker";
import axios from "axios";

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const uploadImage = async () => {
  try {
    const result = await DocumentPicker.getDocumentAsync({
      copyToCacheDirectory: false,
      type: "image/*",
    });

    if (result.type === "success") {
      // upload image with blobs
      let blob = b64toBlob(result.uri.split(",")[1]);
      const formData = new FormData();
      formData.append("file", blob);

      try {
        let resp = await axios.post(
          `${GlobalConstants.townTicksBaseUrl}posts/image`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (resp.data.status === true) {
          return resp.data.data.base_url + resp.data.data.image_name;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    } else if (result.type === "cancel") {
      console.log("Document picking cancelled");
      return "";
    } else {
      console.error("Error picking document:", result);
      return "";
    }
  } catch (err) {
    console.warn(err);
    return false;
  }
};

export const getDisplayDate = async (year, month, day) => {
  let today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  let compDate = new Date(year, month - 1, day); // month - 1 because January == 0
  let diff = today.getTime() - compDate.getTime(); // get the difference between today(at 00:00:00) and the date
  let stringDate = compDate.toDateString().split(" ");
  if (compDate.getTime() > today.getTime()) {
    return stringDate[1] + " " + stringDate[2];
  } else if (compDate.getTime() == today.getTime()) {
    return "Today";
  } else if (diff <= 24 * 60 * 60 * 1000) {
    return "Yesterday";
  } else {
    if (year != today.getFullYear()) {
      return stringDate[1] + " " + stringDate[2] + " " + stringDate[3];
    } else {
      return stringDate[1] + " " + stringDate[2];
    }
  }
};

export const truncateString = (str, maxLength = 30) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  }
  return str;
};


export const validEmail = (str) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const isValidEmail = emailRegex.test(str);
  return isValidEmail;
};
