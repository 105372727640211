import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Center,
  ScrollView,
  Button,
  useToast,
  Stack,
  TextArea,
  View,
  FormControl,
  Input,
  Image,
  Select,
  Icon,
  Pressable,
  Heading,
} from "native-base";
import { GlobalStyles } from "../../constants/styles";
import { GlobalConstants } from "../../constants/constants";
import DatePickerSingleInput from "../../components/DatePickerSingleInput";
import ConfigureSettingsPopup from "../../components/ConfigureSettingsPopup";
import DateRangePickerModel from "../../components/DateRangePickerModel";
import Toast from "../../components/Toast";
import { useSelector } from "react-redux";
import axios from "axios";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { uploadImage } from "../../utils/general";
import AdCard from "../../components/AdCard";
import StripePopup from "../../components/StripePopup";

const AdvertiserMainPage = () => {
  let user = useSelector((state) => state.user.user);
  axios.defaults.headers.common = {
    Authorization: `bearer ${user ? user.token : null}`,
  };

  const toast = useToast();

  const [isNewAdSelected, setIsNewAdSelected] = useState(false);
  const [isCurrentAdsSelected, setIsCurrentAdsSelected] = useState(false);
  const [isAnalyticsSelected, setIsAnalyticsSelected] = useState(false);

  const [isShowPayNow, setIsShowPayNow] = useState(false);
  const [payNowPayload, setPayNowPayload] = useState({
    ad_id: 0,
    amount: 0,
  });
  const [showPayNowCard, setShowPayNowCard] = useState(false);

  const [adCategories, setAdCategories] = useState([]);
  const [ads, setAds] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const [submitPopUp, setSubmitPopUp] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [selectedPost, setSelectedPost] = useState({});

  const [currentAdsBtnTextColor, setCurrentAdsBtnTextColor] = useState(
    GlobalStyles.colors.white
  );
  const [analyticsBtnTextColor, setAnalyticsBtnTextColor] = useState(
    GlobalStyles.colors.white
  );
  const [newAdBtnTextColor, setNewAdBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  function changeColor(btnType) {
    if (btnType === "NewAd") {
      setCurrentAdsBtnTextColor(GlobalStyles.colors.white);
      setAnalyticsBtnTextColor(GlobalStyles.colors.white);
      setNewAdBtnTextColor(GlobalStyles.colors.mustard);
      setIsNewAdSelected(true);
      setIsCurrentAdsSelected(false);
      setIsAnalyticsSelected(false);
    } else if (btnType === "CurrentAds") {
      setCurrentAdsBtnTextColor(GlobalStyles.colors.mustard);
      setAnalyticsBtnTextColor(GlobalStyles.colors.white);
      setNewAdBtnTextColor(GlobalStyles.colors.white);
      setIsNewAdSelected(false);
      setIsCurrentAdsSelected(true);
      setIsAnalyticsSelected(false);
    } else if (btnType === "Analytics") {
      setCurrentAdsBtnTextColor(GlobalStyles.colors.white);
      setAnalyticsBtnTextColor(GlobalStyles.colors.mustard);
      setNewAdBtnTextColor(GlobalStyles.colors.white);
      setIsNewAdSelected(false);
      setIsCurrentAdsSelected(false);
      setIsAnalyticsSelected(true);
    } else {
      setCurrentAdsBtnTextColor(GlobalStyles.colors.white);
      setAnalyticsBtnTextColor(GlobalStyles.colors.white);
      setNewAdBtnTextColor(GlobalStyles.colors.mustard);
      setIsNewAdSelected(true);
      setIsCurrentAdsSelected(false);
      setIsAnalyticsSelected(false);
    }
  }

  const [payload, setPayload] = useState({
    ad_category_id: 0,
    start_date: "",
    end_date: "",
    urls: [],
    text: "",
  });

  const refresh = () => {
    setPayload({
      ad_category_id: 0,
      start_date: "",
      end_date: "",
      urls: [],
      text: "",
    });
  };

  function validateForm() {
    if (!payload.ad_category_id) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"please select Ad Type!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    } else if (!payload.urls[0]) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"please upload image!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    } else if (!payload.start_date || !payload.start_date) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"please select date range!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    }
    // else if (!payload.text) {
    //   toast.show({
    //     render: () => {
    //       return (
    //         <Toast
    //           color={GlobalStyles.colors.red}
    //           message={"please enter ad text!!!"}
    //         />
    //       );
    //     },
    //     placement: "top",
    //   });
    //   return false;
    // }
    return payload;
  }

  const Submit = async (e) => {
    // setPayNowPayload({
    //   ad_id: 77,
    //   amount: 220,
    // });
    // setIsShowPayNow(true);
    let _payload = validateForm();
    if (_payload) {
      try {
        const resp = await axios.post(
          `${GlobalConstants.townTicksBaseUrl}ad`,
          _payload
        );
        if (resp.data.status) {
          setPayNowPayload({
            ad_id: resp.data.data.id,
            amount: resp.data.amount,
          });
          setIsShowPayNow(true);
          // setSubmitPopUp(true);
        } else {
          toast.show({
            render: () => {
              return (
                <Toast
                  color={GlobalStyles.colors.red}
                  message={resp.data.status_msg}
                />
              );
            },
            placement: "top",
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const Delete = async () => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${GlobalConstants.townTicksBaseUrl}ad`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        ad_id: selectedPost.id,
      }),
    };

    axios
      .request(config)
      .then((resp) => {
        if (resp.data.status) {
          refresh();
          setSubmitPopUp(false);
        } else {
          toast.show({
            render: () => {
              return (
                <Toast
                  color={GlobalStyles.colors.red}
                  message={resp.data.status_msg}
                />
              );
            },
            placement: "top",
          });
        }
      })
      .catch((error) => {
        toast.show({
          render: () => {
            return <Toast color={GlobalStyles.colors.red} message={error} />;
          },
          placement: "top",
        });
      });
  };

  const convertDate = (inputFormat) => {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join("-");
  };

  const getAdCategories = async () => {
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}ad/category`
      );
      if (resp.data.status) {
        setAdCategories(resp.data.data);
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to get ad categories!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAds = async () => {
    try {
      const resp = await axios.get(`${GlobalConstants.townTicksBaseUrl}ad`);
      if (resp.data.status) {
        setAds(resp.data.data);
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to get ads!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  async function selectFile() {
    setIsLoading(true);
    let imageUrl = await uploadImage();
    if (imageUrl) {
      const _payload = { ...payload };
      _payload.urls[0] = imageUrl;
      setPayload(_payload);
      setIsLoading(false);
    } else {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"please check image size should be less than 500KB!!!"}
            />
          );
        },
        placement: "top",
      });
    }
  }

  useEffect(() => {
    console.log(window?.innerWidth || yourDefaultWidth);
    changeColor("NewAd");
    getAdCategories();
  }, []);

  const handleChange = useCallback(async (value) => {
    setSubmitPopUp(false);
    window.location.reload();
  }, []);

  const handleDeleteOk = async (isTrue) => {
    setDeletePopUp(false);
    if (isTrue) {
      await Delete();
      getAds();
    }
  };

  const handlePayNow = useCallback(async (value) => {
    setIsShowPayNow(false);
    setShowPayNowCard(true);
  }, []);

  const onDeleteClick = async (data) => {
    console.log(data);
    setDeletePopUp(true);
    setSelectedPost(data);
  };

  return (
    <>
      <ScrollView>
        {user &&
        user.user_category == GlobalConstants.userCategories.business.id ? (
          <Center>
            <HStack bg="#F5F5F5" alignItems="center" w="100%" maxW="900">
              <Button
                ml={5}
                alignSelf={"start"}
                size="sm"
                variant="link"
                onPress={() => {
                  window.location.reload();
                  // navigation.navigate("Home");
                }}
              >
                Go to Home Page
              </Button>
            </HStack>
          </Center>
        ) : (
          <></>
        )}

        <Box>
          <Center>
            <Box safeAreaTop bg="white" />
            <Box
              h={"550"}
              bg="#F5F5F5"
              px="1"
              py="3"
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              maxW="900"
            >
              <VStack alignItems="center">
                <Stack
                  mt={10}
                  w={[230, 230, 230]}
                  justifyContent="center"
                  flexDirection={"row"}
                >
                  <Button
                    size="sm"
                    mx={1}
                    variant={"solid"}
                    px="3"
                    bg={GlobalStyles.colors.blue}
                    borderColor={currentAdsBtnTextColor}
                    borderWidth={2}
                    onPress={() => {
                      changeColor("CurrentAds");
                      getAds();
                    }}
                  >
                    <Text
                      fontWeight="bold"
                      fontFamily={GlobalStyles.text.fontFamily}
                      color={currentAdsBtnTextColor}
                    >
                      Current Ads
                    </Text>
                  </Button>
                  <Button
                    size="sm"
                    variant={"solid"}
                    mx={1}
                    px="3"
                    bg={GlobalStyles.colors.blue}
                    borderColor={newAdBtnTextColor}
                    borderWidth={2}
                    onPress={() => {
                      changeColor("NewAd");
                    }}
                  >
                    <Text
                      fontWeight="bold"
                      fontFamily={GlobalStyles.text.fontFamily}
                      color={newAdBtnTextColor}
                    >
                      New Ad
                    </Text>
                  </Button>
                  <Button
                    size="sm"
                    mx={1}
                    variant={"solid"}
                    px="3"
                    bg={GlobalStyles.colors.blue}
                    borderColor={analyticsBtnTextColor}
                    borderWidth={2}
                    onPress={() => {
                      changeColor("Analytics");
                      getAds();
                    }}
                  >
                    <Text
                      fontWeight="bold"
                      fontFamily={GlobalStyles.text.fontFamily}
                      color={analyticsBtnTextColor}
                    >
                      Analytics
                    </Text>
                  </Button>
                </Stack>

                {isNewAdSelected ? (
                  <>
                    <Center>
                      <FormControl mt={5} w={[230, 230, 230]}>
                        <Select
                          selectedValue={payload.ad_category_id}
                          minWidth="200"
                          size="6"
                          accessibilityLabel="Ad Type"
                          placeholder="Ad Type"
                          _selectedItem={{
                            bg: "teal.600",
                          }}
                          mt="1"
                          onValueChange={(x) => {
                            const _payload = { ...payload };
                            _payload.ad_category_id = x;
                            setPayload(_payload);
                          }}
                        >
                          {adCategories.map((x, index) => {
                            return (
                              <Select.Item
                                key={index}
                                label={x.title.toString()}
                                value={x.id.toString()}
                              />
                            );
                          })}
                        </Select>
                      </FormControl>

                      <FormControl mt={5} w={[230, 230, 230]}>
                        <Pressable
                          onPress={() => {
                            selectFile();
                          }}
                        >
                          <Input
                            value={
                              payload.urls.length > 0
                                ? payload.urls[0]
                                : "Upload Image"
                            }
                            InputRightElement={
                              <Button
                                onPress={() => {
                                  selectFile();
                                }}
                                isLoading={isLoading}
                                bgColor={GlobalStyles.colors.lightGrey}
                                _loading={{
                                  bg: "muted.500",
                                }}
                                _spinner={{
                                  color: "white",
                                }}
                              >
                                <Icon
                                  size="6"
                                  color="muted.500"
                                  as={<MaterialIcons name="file-upload" />}
                                />
                              </Button>
                            }
                          />
                        </Pressable>
                      </FormControl>

                      {/* <DatePickerSingleInput
                      onChange={(x) => {
                        const _payload = { ...payload };
                        _payload.start_date = x;
                        _payload.end_date = x;
                        setPayload(_payload);
                      }}
                    /> */}

                      <FormControl mt={5} w={[230, 230, 230]}>
                        <Pressable onPress={() => setOpen(true)}>
                          <Input
                            value={
                              payload.start_date && payload.end_date
                                ? payload.start_date + " to " + payload.end_date
                                : "Select Date Range"
                            }
                            InputRightElement={
                              <Button
                                bgColor={GlobalStyles.colors.lightGrey}
                                onPress={() => setOpen(true)}
                              >
                                <Icon
                                  size="6"
                                  color="muted.500"
                                  as={<MaterialIcons name="calendar-today" />}
                                />
                              </Button>
                            }
                          />
                        </Pressable>
                      </FormControl>

                      {open ? (
                        <>
                          <DateRangePickerModel
                            onClose={(open) => {
                              setOpen(open);
                            }}
                            onChange={(startDate, endDate) => {
                              console.log(startDate, endDate);
                              if (
                                startDate == "NaN/NaN/NaN" ||
                                endDate == "NaN/NaN/NaN"
                              ) {
                                toast.show({
                                  render: () => {
                                    return (
                                      <Toast
                                        color={GlobalStyles.colors.red}
                                        message={
                                          "please select valid date range!!!"
                                        }
                                      />
                                    );
                                  },
                                  placement: "top",
                                });
                                setOpen(false);
                              } else {
                                const _payload = { ...payload };
                                _payload.start_date = startDate;
                                _payload.end_date = endDate;
                                setPayload(_payload);
                                setOpen(false);
                              }
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}

                      <FormControl mt={5} w={[230, 230, 230]}>
                        <TextArea
                          borderWidth={1}
                          placeholder="Write Something..."
                          // value={postTextArea}
                          onChange={(e) =>
                            (payload.text = e.currentTarget.value)
                          } // for web
                          onChangeText={(text) => (payload.text = text)} // for android and ios
                        />
                      </FormControl>

                      <Button
                        mt="5"
                        size="sm"
                        variant={"solid"}
                        px="3"
                        bg={GlobalStyles.colors.blue}
                        borderColor={GlobalStyles.colors.mustard}
                        borderWidth={2}
                        onPress={Submit}
                        isDisabled={isLoading}
                      >
                        Submit
                      </Button>
                    </Center>
                  </>
                ) : (
                  <></>
                )}

                {isCurrentAdsSelected || isAnalyticsSelected ? (
                  <>
                    <ScrollView
                      w={[250, 360, 550]}
                      showsVerticalScrollIndicator={false}
                    >
                      <VStack alignItems="center">
                        <Stack
                          justifyContent="center"
                          flexDirection={"row"}
                          space={3}
                          flexWrap="wrap"
                          m={2}
                        >
                          {ads.map((x, index) => {
                            return (
                              <AdCard
                                data={x}
                                showViewCount={isAnalyticsSelected}
                                setSelectedPost={onDeleteClick}
                              />
                            );
                          })}
                        </Stack>
                      </VStack>
                    </ScrollView>
                  </>
                ) : (
                  <></>
                )}
              </VStack>
            </Box>
          </Center>
          {submitPopUp ? (
            <ConfigureSettingsPopup
              heading="Ad Submitted"
              body="Your ad is under review. When it becomes visible in the field, we will notify you."
              data={submitPopUp}
              onChange={handleChange}
            />
          ) : (
            <></>
          )}

          {deletePopUp ? (
            <ConfigureSettingsPopup
              heading="Confirm Deletion"
              body="Are you sure you want to delete"
              data={deletePopUp}
              onChange={handleDeleteOk}
            />
          ) : (
            <></>
          )}

          {isShowPayNow ? (
            <ConfigureSettingsPopup
              heading="Payment Details"
              body={
                "Are you sure you want to pay amount " + payNowPayload.amount
              }
              data={isShowPayNow}
              onChange={handlePayNow}
            />
          ) : (
            <></>
          )}

          {showPayNowCard ? (
            <StripePopup
              heading={"Confirm Payment"}
              body={payNowPayload}
              data={showPayNowCard}
              onChange={handleChange}
            />
          ) : (
            <></>
          )}
        </Box>
      </ScrollView>
    </>
  );
};

export default AdvertiserMainPage;
