import React, { useState, useEffect } from "react";
import {
  Box,
  HStack,
  VStack,
  Text,
  Center,
  ScrollView,
  Button,
  useToast,
  Stack,
  TextArea,
  View,
  FormControl,
  Pressable,
  Input,
  Icon,
} from "native-base";
import { GlobalStyles } from "../../constants/styles";
import FeedCard from "../../components/FeedCard";
import { GlobalConstants } from "../../constants/constants";
import { useNavigation } from "@react-navigation/native";
import Toast from "../../components/Toast";
import { useSelector } from "react-redux";
import axios from "axios";
import ConfigureSettingsPopup from "../../components/ConfigureSettingsPopup";
import { uploadImage } from "../../utils/general";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import FeedCardNew from "../../components/FeedCardNew";

const ChamberOfCommerceMainPage = () => {
  let user = useSelector((state) => state.user.user);
  axios.defaults.headers.common = {
    Authorization: `bearer ${user ? user.token : null}`,
  };

  const toast = useToast();
  const navigation = useNavigation();

  const [postTextArea, setPostTextArea] = useState("");
  const [postDetails, setPostDetails] = useState("");
  const [postType, setPostType] = useState(
    GlobalConstants.postCategories.jobOpening.id
  );
  const [postImage, setPostImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [ticksBtnTextColor, setTicksBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [addTickBtnTextColor, setAddTickBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [addOpeningBtnTextColor, setAddOpeningBtnTextColor] = useState(
    GlobalStyles.colors.white
  );

  const [isTicksView, setIsTicksView] = useState(false);
  const [cityGovPosts, setCityGovPosts] = useState([]);

  const [deletePopUp, setDeletePopUp] = useState(false);
  const [selectedPost, setSelectedPost] = useState({});

  function changeColor(btnType) {
    if (btnType === "AddTick") {
      setTicksBtnTextColor(GlobalStyles.colors.white);
      setAddTickBtnTextColor(GlobalStyles.colors.mustard);
      setAddOpeningBtnTextColor(GlobalStyles.colors.white);
    } else if (btnType === "AddOpening") {
      setTicksBtnTextColor(GlobalStyles.colors.white);
      setAddTickBtnTextColor(GlobalStyles.colors.white);
      setAddOpeningBtnTextColor(GlobalStyles.colors.mustard);
    } else if (btnType === "Ticks") {
      setTicksBtnTextColor(GlobalStyles.colors.mustard);
      setAddTickBtnTextColor(GlobalStyles.colors.white);
      setAddOpeningBtnTextColor(GlobalStyles.colors.white);
    } else {
      setTicksBtnTextColor(GlobalStyles.colors.white);
      setAddTickBtnTextColor(GlobalStyles.colors.mustard);
      setAddOpeningBtnTextColor(GlobalStyles.colors.white);
    }
  }

  const createPost = async () => {
    try {
      var obj = {
        text: postTextArea,
        details: postDetails,
        type: postType,
      };

      if (postImage) {
        obj.urls = [postImage];
      }
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}posts`,
        obj
      );
      if (resp.data.status) {
        setPostImage("");
        setPostTextArea("");
        setPostDetails("");
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.mustard}
                message={"tick created successfuly!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={resp.data.status_msg}
              />
            );
          },
          placement: "top",
        });
      }
      setPostTextArea("");
      setPostDetails("");
    } catch (error) {
      changeColor("AddOpening");
      console.log(error);
    }
  };

  const getAllPosts = async () => {
    try {
      const resp = await axios.get(
        `${GlobalConstants.townTicksBaseUrl}v1/posts`
      );
      if (resp.data.status) {
        let feedArray = resp.data.data;
        setCityGovPosts(feedArray);
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to get posts!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Delete = async () => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${GlobalConstants.townTicksBaseUrl}posts`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({
        post_id: selectedPost.id,
      }),
    };

    axios
      .request(config)
      .then((resp) => {
        if (resp.data.status) {
          getAllPosts();
        } else {
          toast.show({
            render: () => {
              return (
                <Toast
                  color={GlobalStyles.colors.red}
                  message={resp.data.status_msg}
                />
              );
            },
            placement: "top",
          });
        }
      })
      .catch((error) => {
        toast.show({
          render: () => {
            return <Toast color={GlobalStyles.colors.red} message={error} />;
          },
          placement: "top",
        });
      });
  };

  const handleDeleteOk = async (isTrue) => {
    setDeletePopUp(false);
    if (isTrue) {
      await Delete();
    }
  };

  const onDeleteClick = async (data) => {
    // console.log(data);
    setDeletePopUp(true);
    setSelectedPost(data);
  };

  async function selectFile() {
    setIsLoading(true);
    let imageUrl = await uploadImage();
    if (imageUrl) {
      setPostImage(imageUrl);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"please check image size should be less than 500KB!!!"}
            />
          );
        },
        placement: "top",
      });
    }
  }

  useEffect(async () => {
    console.log(window?.innerWidth || yourDefaultWidth);
    changeColor("AddOpening");
  }, []);

  return (
    <>
      <ScrollView>
        <Center>
          <Box safeAreaTop bg="white" />
          <Box
            h={"550"}
            bg="#F5F5F5"
            px="1"
            py="3"
            justifyContent="space-between"
            alignItems="center"
            w="100%"
            maxW="900"
          >
            <VStack alignItems="center">
              <Stack
                mt={10}
                w={[230, 230, 230]}
                justifyContent="center"
                flexDirection={"row"}
              >
                <Button
                  size="sm"
                  mx={1}
                  variant={"solid"}
                  px="3"
                  bg={GlobalStyles.colors.blue}
                  borderColor={ticksBtnTextColor}
                  borderWidth={2}
                  onPress={() => {
                    changeColor("Ticks");
                    setIsTicksView(true);
                    getAllPosts();
                  }}
                >
                  <Text
                    fontWeight="bold"
                    fontFamily={GlobalStyles.text.fontFamily}
                    color={ticksBtnTextColor}
                  >
                    Ticks
                  </Text>
                </Button>
                {/* <Button
                  size="sm"
                  variant={"solid"}
                  mx={1}
                  px="3"
                  bg={GlobalStyles.colors.blue}
                  borderColor={addTickBtnTextColor}
                  borderWidth={2}
                  onPress={() => {
                    setIsTicksView(false);
                    changeColor("AddTick");
                    setPostType(GlobalConstants.postCategories.tick.id);
                  }}
                >
                  <Text
                    fontWeight="bold"
                    fontFamily={GlobalStyles.text.fontFamily}
                    color={addTickBtnTextColor}
                  >
                    Add Tick
                  </Text>
                </Button> */}
                <Button
                  size="sm"
                  variant={"solid"}
                  mx={1}
                  px="3"
                  bg={GlobalStyles.colors.blue}
                  borderColor={addOpeningBtnTextColor}
                  borderWidth={2}
                  onPress={() => {
                    setIsTicksView(false);
                    changeColor("AddOpening");
                    setPostType(GlobalConstants.postCategories.jobOpening.id);
                  }}
                >
                  <Text
                    fontWeight="bold"
                    fontFamily={GlobalStyles.text.fontFamily}
                    color={addOpeningBtnTextColor}
                  >
                    Add Opening
                  </Text>
                </Button>
              </Stack>

              {isTicksView ? (
                <>
                  <ScrollView
                    w={[260, 360, 610]}
                    showsVerticalScrollIndicator={false}
                  >
                    <VStack alignItems="center">
                      <Stack
                        justifyContent="center"
                        flexDirection={"row"}
                        space={3}
                        flexWrap="wrap"
                        m={2}
                      >
                        {cityGovPosts.map((x, index) => {
                          return (
                            <FeedCardNew
                              data={x}
                              showViewCount={false}
                              setSelectedPost={onDeleteClick}
                            />
                          );
                        })}
                      </Stack>
                    </VStack>
                  </ScrollView>
                </>
              ) : (
                <>
                  <Box mt={10} alignItems="center" w={[250, 350, 400]}>
                    <FormControl mt={2} w={[250, 350, 400]}>
                      <Pressable
                        onPress={() => {
                          selectFile();
                        }}
                      >
                        <Input
                          value={postImage ? postImage : "Upload Image"}
                          InputRightElement={
                            <Button
                              onPress={() => {
                                selectFile();
                              }}
                              isLoading={isLoading}
                              bgColor={GlobalStyles.colors.lightGrey}
                              _loading={{
                                bg: "muted.500",
                              }}
                              _spinner={{
                                color: "white",
                              }}
                            >
                              <Icon
                                size="6"
                                color="muted.500"
                                as={<MaterialIcons name="file-upload" />}
                              />
                            </Button>
                          }
                        />
                      </Pressable>
                    </FormControl>
                    <FormControl mt={2} w={[250, 350, 400]}>
                      <TextArea
                        borderColor={GlobalStyles.colors.mustard}
                        borderWidth={1}
                        placeholder="Summary"
                        value={postTextArea}
                        onChange={(e) => setPostTextArea(e.currentTarget.value)} // for web
                        onChangeText={(text) => setPostTextArea(text)} // for android and ios
                        maxLength={300}
                        w={[250, 350, 400]}
                      />
                    </FormControl>
                    <Text
                      alignSelf={"flex-end"}
                      fontSize="xs"
                      fontFamily={GlobalStyles.text.fontFamily}
                    >
                      300 words
                    </Text>
                    <FormControl mt={2} w={[250, 350, 400]}>
                      <TextArea
                        borderColor={GlobalStyles.colors.mustard}
                        borderWidth={1}
                        placeholder="Details"
                        value={postDetails}
                        onChange={(e) => setPostDetails(e.currentTarget.value)} // for web
                        onChangeText={(text) => setPostDetails(text)} // for android and ios
                        maxLength={1500}
                        w={[250, 350, 400]}
                      />
                    </FormControl>
                    <Text
                      alignSelf={"flex-end"}
                      fontSize="xs"
                      fontFamily={GlobalStyles.text.fontFamily}
                    >
                      1500 words
                    </Text>
                    <Button
                      size="sm"
                      variant={"solid"}
                      mx={1}
                      px="3"
                      bg={GlobalStyles.colors.blue}
                      borderColor={GlobalStyles.colors.mustard}
                      borderWidth={2}
                      onPress={() => {
                        createPost();
                      }}
                    >
                      <Text
                        fontWeight="bold"
                        fontFamily={GlobalStyles.text.fontFamily}
                        color={GlobalStyles.colors.white}
                      >
                        Submit
                      </Text>
                    </Button>
                  </Box>
                </>
              )}
            </VStack>
            {deletePopUp ? (
              <ConfigureSettingsPopup
                heading="Confirm Deletion"
                body="Are you sure you want to delete"
                data={deletePopUp}
                onChange={handleDeleteOk}
              />
            ) : (
              <></>
            )}
          </Box>
        </Center>
      </ScrollView>
    </>
  );
};

export default ChamberOfCommerceMainPage;
