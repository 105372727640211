import React, { useState, useEffect } from "react";
import {
  Box,
  HStack,
  Text,
  Center,
  Button,
  useToast,
  FormControl,
  Select,
  Input,
  Heading,
} from "native-base";
import { GlobalStyles } from "../../constants/styles";
import { GlobalConstants } from "../../constants/constants";
import ConfigureSettingsPopup from "../../components/ConfigureSettingsPopup";
import { useNavigation } from "@react-navigation/native";
import Toast from "../../components/Toast";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import * as DocumentPicker from "expo-document-picker";
import SetUser from "../../redux/user/SetUser";
import { uploadImage } from "../../utils/general";
import ChangePassword from "../../components/ChangePassword";

const CityGovSettingsPage = () => {
  const dispatch = useDispatch();
  let user = useSelector((state) => state.user.user);
  axios.defaults.headers.common = {
    Authorization: `bearer ${user ? user.token : null}`,
  };

  const toast = useToast();
  const navigation = useNavigation();

  const [session, setSession] = useState(user);

  const [selectedCity, setSelectedCity] = useState(user.city_id.toString());
  const [officialName, setOfficialName] = useState(user.profile.name);
  const [logoUrl, setlogoUrl] = useState(user.logo_url);

  const [cityProfileSettingsTextColor, setProfileSettingsBtnTextColor] =
    useState(GlobalStyles.colors.mustard);

  const [changePasswordBtnTextColor, setChangePasswordBtnTextColor] = useState(
    GlobalStyles.colors.blue
  );

  const [isProfileNameSelected, setIsProfileNameSelected] = useState(true);
  useState(false);
  const [isChangePasswordSelected, setIsChangePasswordSelected] =
    useState(false);

  const [cities, setCities] = useState([]);

  function changeColor(btnType) {
    if (btnType === "ProfileSettings") {
      setIsProfileNameSelected(true);
      setIsChangePasswordSelected(false);
      setProfileSettingsBtnTextColor(GlobalStyles.colors.mustard);
      setChangePasswordBtnTextColor(GlobalStyles.colors.blue);
    } else if (btnType === "ChangePassword") {
      setIsProfileNameSelected(false);
      setIsChangePasswordSelected(true);
      setProfileSettingsBtnTextColor(GlobalStyles.colors.blue);
      setChangePasswordBtnTextColor(GlobalStyles.colors.mustard);
    } else {
      setIsProfileNameSelected(true);
      setIsChangePasswordSelected(false);
      setProfileSettingsBtnTextColor(GlobalStyles.colors.mustard);
      setChangePasswordBtnTextColor(GlobalStyles.colors.blue);
    }
  }

  const getAllCities = async () => {
    try {
      const resp = await axios.get(`${GlobalConstants.townTicksBaseUrl}cities`);
      if (resp.data.status) {
        setCities(resp.data.data);
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to get cities!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async () => {
    if (!selectedCity) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"kindly select city!!!"}
            />
          );
        },
        placement: "top",
      });
      return false;
    }

    if (!officialName) {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"kindly enter official name!!!"}
            />
          );
        },
        placement: "top",
      });
    }

    try {
      const resp = await axios.post(
        `${GlobalConstants.townTicksBaseUrl}users/v1/settings`,
        {
          logo_url: logoUrl,
          city_id: selectedCity,
          name: officialName,
        }
      );
      if (resp.data.status) {
        let profile = { ...session.profile };
        let data = { ...session };
        console.log("data", data);
        data.city_id = selectedCity;
        data.logo_url = logoUrl;
        profile.name = officialName;
        data.profile = profile;
        dispatch(SetUser.action(null));
        dispatch(SetUser.action(data));
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.green}
                message={"successfully saved!!!"}
              />
            );
          },
          placement: "top",
        });
      } else {
        toast.show({
          render: () => {
            return (
              <Toast
                color={GlobalStyles.colors.red}
                message={"Failed to save city!!!"}
              />
            );
          },
          placement: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function selectFile() {
    let imageUrl = await uploadImage();
    if (imageUrl) {
      setlogoUrl(imageUrl);
    } else {
      toast.show({
        render: () => {
          return (
            <Toast
              color={GlobalStyles.colors.red}
              message={"please check image size should be less than 500KB!!!"}
            />
          );
        },
        placement: "top",
      });
    }
  }

  useEffect(async () => {
    console.log(window?.innerWidth || yourDefaultWidth);
    await getAllCities();
  }, []);

  return (
    <>
      <HStack space={3} justifyContent="center">
        <Center w={[75, 150, 220]} rounded="md">
          <Button
            width={window?.innerWidth > 360 ? 90 : 65}
            size="sm"
            variant={"outline"}
            px="2"
            my="2"
            shadow={3}
            onPress={() => {
              changeColor("ProfileSettings");
            }}
          >
            <Text
              fontWeight="bold"
              fontFamily={GlobalStyles.text.fontFamily}
              color={cityProfileSettingsTextColor}
            >
              Profile{"\n"}
              Settings
            </Text>
          </Button>
          <Button
            width={window?.innerWidth > 360 ? 90 : 65}
            size="sm"
            variant={"outline"}
            px="2"
            my="2"
            shadow={3}
            onPress={() => {
              changeColor("ChangePassword");
            }}
          >
            <Text
              fontWeight="bold"
              fontFamily={GlobalStyles.text.fontFamily}
              color={changePasswordBtnTextColor}
            >
              Change{"\n"}
              Password
            </Text>
          </Button>
        </Center>

        <Center h={350} w={[225, 400, 630]} rounded="md" shadow={3}>
          {isProfileNameSelected ? (
            <>
              <Box>
                <FormControl>
                  <FormControl.Label>Official Name</FormControl.Label>
                  <Input
                    value={officialName}
                    onChange={(x) => {
                      setOfficialName(x.target.value);
                    }}
                  />
                </FormControl>

                <FormControl>
                  <FormControl.Label>City</FormControl.Label>
                  <Center>
                    <Select
                      minWidth="200"
                      maxWidth="400"
                      accessibilityLabel="Choose City"
                      placeholder="Choose City"
                      _selectedItem={{
                        bg: "teal.600",
                      }}
                      mt="1"
                      onValueChange={(x) => {
                        setSelectedCity(x);
                      }}
                      selectedValue={selectedCity}
                      isDisabled={true}
                    >
                      {cities.map((x, index) => {
                        return (
                          <Select.Item
                            key={index}
                            label={x.title.toString()}
                            value={x.id.toString()}
                          />
                        );
                      })}
                    </Select>
                  </Center>
                </FormControl>
                <FormControl>
                  <FormControl.Label>Upload Logo (Optional)</FormControl.Label>
                  <Input value={logoUrl ? logoUrl : ""} isDisabled={true} />
                  <Button
                    alignSelf={"flex-start"}
                    variant={"outline"}
                    size={"sm"}
                    onPress={() => {
                      selectFile();
                    }}
                  >
                    Browse
                  </Button>
                  <Text fontSize="xs">Limit:500KB</Text>
                </FormControl>
                <Center>
                  <Button
                    w={12}
                    mt="5"
                    size="sm"
                    variant={"solid"}
                    px="3"
                    bg={GlobalStyles.colors.blue}
                    borderColor={GlobalStyles.colors.mustard}
                    borderWidth={2}
                    onPress={onSubmit}
                  >
                    Save
                  </Button>
                </Center>
              </Box>
            </>
          ) : (
            <></>
          )}

          {isChangePasswordSelected ? <ChangePassword /> : <></>}
        </Center>
      </HStack>
    </>
  );
};

export default CityGovSettingsPage;
